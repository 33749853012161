import React, { useState } from 'react'
import { useCities } from 'utils/cityUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import { pageSize } from 'shared/utils/pagination'
import Loader from 'components/shared/loader/Loader'
import { usePagedCompanies } from 'utils/companyUtils'
import RepairmenForm from 'components/forms/repairman/RepairmenForm'
import Pagination from 'components/shared/pagination/Pagination'
import RepairmenList from 'components/shared/list/RepairmenList'

const RepairmenScreen = () => {
  const { params } = useURLQuery()

  const [page, setPage] = useState(0)

  const cities = useCities()

  // const [sortBy, setSortBy] = useState()
  const repairmenQuery = usePagedCompanies({
    page,
    size: pageSize,
    isRepairman: true,
    name: params.search,
    'address.city': cities.data?.byId[params.city]?.name,
    'categories.id': params.category,
    'subCategories.id': params.subCategory,
  })

  if (repairmenQuery.error) {
    return <Loader />
  }
  if (repairmenQuery.status === 'loading') {
    return <Loader />
  }

  return (
    <div>
      <div className="gradient">
        <div className="global-container">
          <RepairmenForm />
        </div>
      </div>
      <div className="global-container">
        <RepairmenList data={repairmenQuery.data?.page} />
        <Pagination
          setActivePage={setPage}
          pageCount={repairmenQuery.data?.total / pageSize}
        />
      </div>
    </div>
  )
}

export default RepairmenScreen
