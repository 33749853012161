import React from 'react'
import './RangeSlider.css'
import '../../../../node_modules/rc-slider/assets/index.css'
import { Range } from 'rc-slider'

interface Props {
  onChange(value): void
  value: number[]
  max?: number
  min?: number
}

const ERange = ({ onChange, value, max, min }: Props) => {
  return (
    <div className="filter-slider">
      <Range
        className="filter-slider__input"
        min={min}
        max={max}
        value={value}
        allowCross={false}
        onChange={onChange}
      />
      <div className="filter-slider__details">
        <div className="filter-slider__details-section">
          <span>{`${value ? value[0] : min} rsd`}</span>
        </div>
        <div className="filter-slider__details-section">
          <span>{`${value ? value[1] : max} rsd`}</span>
        </div>
      </div>
    </div>
  )
}

export default ERange
