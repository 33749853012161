import React from 'react'
import left from '../../../assets/images/icons/left.svg'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import styles from './BackButton.module.css'

const BackButton: React.FC = () => {
  const history = useHistory()

  return (
    <Link
      to="/#"
      onClick={() => {
        history.goBack()
      }}
    >
      <div className={styles.backArrow}>
        <img src={left} alt="back" />
      </div>
    </Link>
  )
}

export default BackButton
