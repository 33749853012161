import React from 'react'
import { CompanyDto } from 'types/dto'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './CompanyHeader.module.css'
import BackButton from 'components/shared/backButton/BackButton'

const MapPinIcon = () => (
  <FontAwesomeIcon
    icon={faMapMarkerAlt}
    style={{
      fontSize: '14px',
      marginRight: '7px',
      color: '#ccc',
    }}
  />
)

const handleScrollToMap = () => {
  const element = document.getElementById('companyMap') as HTMLElement
  if (element) {
    window.scrollTo({
      top: element.offsetTop - 50,
      left: 0,
      behavior: 'smooth',
    })
  }
}
interface Props {
  company: CompanyDto
}
const CompanyHeader: React.FC<Props> = ({ company }) => {
  return (
    <div className={`${styles.companyHeader} global-container`}>
      <div className={styles.companyHeader__left}>
        <BackButton />
        <div className={styles.basicInfo}>
          <div className={styles.basicInfo__upper}>
            <h2>{company.name}</h2>
            {company.promoted && (
              <div className={styles.fakeButton}>
                <p>Premium član</p>
              </div>
            )}
          </div>
          <div>
            <div
              className={styles.location}
              onClick={() => handleScrollToMap()}
            >
              <MapPinIcon />
              <p>{`${company.address.street} ${company.address.number}, ${company.address.city}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.companyHeader__right}>
        <a href={company.webPageUrl} target="_blank" rel="noopener noreferrer">
          <img
            className={styles.companyLogo}
            src={company.logoUrl}
            alt="CompanyLogo"
          />
        </a>
        {/* <Ratings initialRating={company.rating.average} /> */}
      </div>
    </div>
  )
}

export default CompanyHeader
