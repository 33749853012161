import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { AlbumDto, ImageDto } from 'types/dto'
import { useAlbums } from 'utils/albumUtils'
import EmptyTab from '../../EmptyTab'
// import BeforeAfterImage from '../../../beforeAfterImage/BeforeAfterImage'
// import Image360 from '../../../image360/Image360'
import styles from './Gallery.module.css'

const AlbumItem: React.FC<any> = ({ image, onClick }) => {
  return (
    <div onClick={onClick} className={styles.item}>
      <div
        className={styles.item_bckg}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className={styles.gradient} />
        <div className={styles.item_paragraph}>
          <p>Uvecaj</p>
        </div>
      </div>
      {/* <h3>{image.name}</h3> */}
    </div>
  )
}

// const Album360ImageItem = ({ image360 }) => {
//   return (
//     <div className="gallery--360image">
//       <Image360 images={image360.images} />

//       {/* <h3>{image360.name}</h3> */}
//     </div>
//   )
// }

const Album: React.FC<any> = ({
  album,
  onClick,
}: {
  album: AlbumDto
  onClick(): void
}) => {
  return (
    <div onClick={onClick} className={styles.item}>
      <div
        className={styles.item_bckg}
        style={{ backgroundImage: `url(${album.coverImageUrl})` }}
      >
        <div className={styles.gradient} />
        <div className={styles.item_paragraph}>
          <p>Pogledaj album</p>
        </div>
      </div>
      <h3>{album.name}</h3>
    </div>
  )
}

const Gallery: React.FC<any> = ({ albumIds, breadcrumbStart = 'Nazad' }) => {
  const albumQuery = useAlbums(albumIds)
  const albums = albumQuery.data?.all
  const [activeAlbum, setActiveAlbum] = useState<AlbumDto>(null)
  const [activeAlbumItem, setActiveAlbumItem] = useState<ImageDto>(null)
  if (!albums || albums.length === 0) {
    return <EmptyTab text="Nema albuma" />
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3
          onClick={() => {
            setActiveAlbum(null)
            setActiveAlbumItem(null)
          }}
        >
          Albumi
        </h3>
        <div className={styles.breadcrumbs}>
          <h4
            onClick={() => {
              setActiveAlbum(null)
              setActiveAlbumItem(null)
            }}
          >
            {breadcrumbStart}
          </h4>
          {activeAlbum && (
            <>
              <p>/</p>
              <h4
                onClick={() => {
                  setActiveAlbum(activeAlbum)
                  setActiveAlbumItem(null)
                }}
              >
                {activeAlbum.name}
              </h4>
            </>
          )}
        </div>
      </div>
      <div className={styles.wrapper}>
        {activeAlbum
          ? activeAlbum?.images.map((image, index) => (
              <AlbumItem
                image={image.url}
                key={index}
                onClick={() => {
                  setActiveAlbumItem(image)
                }}
              />
            ))
          : albums.map((album, index) => (
              <Album
                album={album}
                key={index}
                onClick={() => {
                  setActiveAlbum(album)
                }}
              />
            ))}
        {activeAlbumItem && activeAlbumItem.url && (
          <Lightbox
            mainSrc={
              activeAlbum.images[activeAlbum.images.indexOf(activeAlbumItem)]
                .url
            }
            nextSrc={
              activeAlbum.images[
                (activeAlbum.images.indexOf(activeAlbumItem) + 1) %
                  activeAlbum.images.length
              ].url
            }
            prevSrc={
              activeAlbum.images[
                (activeAlbum.images.indexOf(activeAlbumItem) +
                  activeAlbum.images.length -
                  1) %
                  activeAlbum.images.length
              ].url
            }
            onCloseRequest={() => setActiveAlbumItem(null)}
            onMovePrevRequest={() =>
              setActiveAlbumItem(
                activeAlbum.images[
                  (activeAlbum.images.indexOf(activeAlbumItem) +
                    activeAlbum.images.length -
                    1) %
                    activeAlbum.images.length
                ]
              )
            }
            onMoveNextRequest={() =>
              setActiveAlbumItem(
                activeAlbum.images[
                  (activeAlbum.images.indexOf(activeAlbumItem) + 1) %
                    activeAlbum.images.length
                ]
              )
            }
          />
        )}

        {/* {activeAlbumItem && activeAlbumItem.leftImage && (
          <BeforeAfterImage
            before={activeAlbumItem.leftImage}
            after={activeAlbumItem.rightImage}
            onCloseClick={() => setActiveAlbumItem(null)}
          />
        )} */}
      </div>
    </div>
  )
}

export default Gallery

// {activeAlbum && activeAlbum.images360
//   ? activeAlbum &&
//     activeAlbum.images360 &&
//     activeAlbum.images360.map((image360) => (
//       <Album360ImageItem image360={image360} />
//     ))
//   : activeAlbum
//   ? activeAlbum.images
//     ? activeAlbum.images.map((image, index) => (
//         <AlbumItem
//           image={image.image}
//           key={index}
//           onClick={() => {
//             setActiveAlbumItem(image)
//           }}
//         />
//       ))
//     : activeAlbum.beforeAfterImages.map((baImage, index) => {
//         return (
//           <AlbumItem
//             image={baImage.rightImage}
//             key={index}
//             onClick={() => {
//               setActiveAlbumItem(baImage)
//             }}
//           />
//         )
//       })
//   : albums &&
//     albums.map((album, index) => (
//       <Album
//         album={album}
//         key={index}
//         onClick={() => {
//           setActiveAlbum(album)
//         }}
//       />
//     ))}
