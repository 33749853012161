import { join } from 'lodash'
import { Page, ServiceDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchServices = async (params = {}) => {
  const url = apiUrl + '/service/findAll'
  const { data } = await http.get<Page<ServiceDto>>(url, params)
  return data
}

export const fetchServiceById = async (id: string | number) => {
  const url = apiUrl + '/service/findById'

  const { data } = await http.get<ServiceDto>(url, {
    params: { id },
  })

  return data
}

export const fetchServiceByIds = async (ids: number[]) => {
  const url = apiUrl + '/service/findByIds'

  const { data } = await http.get<ServiceDto[]>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}
