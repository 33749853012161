import React from 'react'
import Parser from 'html-react-parser'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import ProductTabs from './ProductTabs'
import { routePaths } from 'routes/routes'
import { useCompany } from 'utils/companyUtils'
import Loader from 'components/shared/loader/Loader'
import { useSubCategories } from 'utils/categoryUtils'
// import Ratings from 'components/shared/ratings/Rating'
import { getProductPrice } from 'shared/utils/getProductPrice'
import { useProduct, useProductByIds } from 'utils/productUtils'
import styles from './ProductScreen.module.css'
import BackButton from 'components/shared/backButton/BackButton'

const ProductScreen = () => {
  const { id } = useParams()
  const product = useProduct(id)
  const relatedProducts = useProductByIds(product.data?.relatedProductIds)
  const subCategories = useSubCategories()

  const manufacturer = useCompany(product.data?.manufacturerId)

  if (product.status === 'error') {
    //TODO return  Not Found
    return <Loader />
  }

  if (product.status === 'loading') {
    return <Loader />
  }

  const {
    name,
    shortDescription,
    manufacturerId,
    coverImageUrl,
    attributes,
    // rating,
    subCategoryIds,
    priceFrom,
    priceTo,
  } = product.data

  return (
    <div className={`${styles.singleProductScreen} global-container`}>
      <div className={styles.product_header}>
        <div className={styles.product_header__left}>
          <BackButton />
          <img
            className={styles.product_header_image}
            src={coverImageUrl}
            alt=""
          />
        </div>
        <div className={styles.product_header_content}>
          <div className={styles.product_title}>
            <div>
              <h3>{name}</h3>
              <p>
                {subCategories.data?.all.map((subCategory) =>
                  subCategoryIds.includes(subCategory.id)
                    ? `${subCategory.name} `
                    : ''
                )}
              </p>

              <Link
                to={routePaths.COMPANY.replace(
                  ':id',
                  manufacturerId.toString()
                )}
              >
                <p>{manufacturer.data?.name}</p>
              </Link>
              {/* <Ratings initialRating={rating.average} /> */}
              <p className={styles.product_price}>
                {getProductPrice(priceFrom, priceTo)}
              </p>
            </div>
            <div className={styles.product_filters}>
              {relatedProducts.data?.map((relatedProduct, index) => {
                return (
                  <Link
                    to={routePaths.PRODUCT.replace(
                      ':id',
                      relatedProduct.id.toString()
                    )}
                    key={index}
                  >
                    <img
                      className="pictogram"
                      src={relatedProduct?.pictogram}
                      alt="product"
                    />
                  </Link>
                )
              })}
            </div>
          </div>

          <div className={styles.product_decription}>
            {Parser(shortDescription || '')}
          </div>
          <div className={styles.product_info}>
            <div>
              {attributes?.map((att) => (
                <p key={att.id}>{`• ${att.name.toUpperCase()}: ${
                  att.value
                } `}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ProductTabs product={product.data} />
    </div>
  )
}

export default ProductScreen
