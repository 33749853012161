import React, { useState } from 'react'
import useURLQuery from 'shared/hooks/useURLQuery'
import { pageSize } from 'shared/utils/pagination'
import Loader from 'components/shared/loader/Loader'
import { usePagedProducts } from 'utils/productUtils'
import Pagination from 'components/shared/pagination/Pagination'
import ProductsForm from 'components/forms/product/ProductsForm'
import ProductList from 'components/shared/list/ProductsList'

const ProductsScreen = () => {
  const [page, setPage] = useState(0)
  const { params } = useURLQuery()

  const products = usePagedProducts({
    page,
    size: pageSize,
    name: params.search,
    'manufacturer.id': params.company,
    'categories.id': params.category,
    'subCategories.id': params.subCategory,
    priceFrom: params.priceFrom,
    priceTo: params.priceTo,
    sort: params.sort,
  })

  if (products.error) {
    return <Loader />
  }
  if (products.status === 'loading') {
    return <Loader />
  }

  return (
    <div>
      <div className="gradient">
        <div className="global-container">
          <ProductsForm />
        </div>
      </div>
      <div className="global-container">
        <ProductList data={products.data?.page} showSortOptions={true} />
        <Pagination
          setActivePage={setPage}
          pageCount={products.data?.total / pageSize}
        />
      </div>
    </div>
  )
}

export default ProductsScreen
