import { useQuery } from 'react-query'
import {
  fetchServiceById,
  fetchServiceByIds,
  fetchServices,
} from '../services/serviceService'

export const usePagedServices = (params: {
  page: number
  size: number
  sort?: string
  [key: string]: any
}) =>
  useQuery(
    ['services', params],
    () =>
      fetchServices({
        params,
      }).then((data) => ({
        page: data.content,
        total: data.totalElements,
      })),
    { keepPreviousData: true }
  )

export const useService = (id: number) =>
  useQuery(['service', id], () => fetchServiceById(id), { enabled: !!id })

export const useServiceByIds = (ids: number[]) =>
  useQuery(['services', ids], () => fetchServiceByIds(ids), { enabled: !!ids })
