import { join } from 'lodash'
import { AlbumDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchAlbumsByIds = async (ids: number[]) => {
  const url = apiUrl + '/album/findByIds'

  const { data } = await http.get<AlbumDto[]>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}
