import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh, faThLarge, faThList } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import styles from './LayoutPicker.module.css'

const ListIcon = ({ isActive }) => (
  <FontAwesomeIcon
    icon={faThList}
    style={{
      fontSize: '20px',
      color: isActive && '#fff',
    }}
  />
)
const ListLargeIcon = ({ isActive }) => (
  <FontAwesomeIcon
    icon={faThLarge}
    style={{
      fontSize: '20px',
      color: isActive && '#fff',
    }}
  />
)
const GridIcon = ({ isActive }) => (
  <FontAwesomeIcon
    icon={faTh}
    style={{
      fontSize: '20px',
      color: isActive && '#fff',
    }}
  />
)

export const useLayoutPicker = ({ initialRowsNumber = 'one' }) => {
  const [rowsNumber, setRowsNumber] = useState(initialRowsNumber)

  const onRowsClick = (rowsNumber) => {
    if (rowsNumber === 'one') {
      setRowsNumber('one')
    } else if (rowsNumber === 'two') {
      setRowsNumber('two')
    } else if (rowsNumber === 'three') {
      setRowsNumber('three')
    }
  }

  const itemClasses = cx(
    styles.threeRowItem,
    rowsNumber === 'two' && styles.twoRowItem,
    rowsNumber === 'one' && styles.rowItem
  )

  return {
    rowsNumber,
    itemClasses,
    onRowsClick,
  }
}

export const LayoutPicker = ({ rowsNumber, onRowsClick }) => {
  return (
    <div className={styles.list_buttons}>
      <button
        className={styles.list_button}
        onClick={() => onRowsClick('one')}
        style={{
          backgroundColor: rowsNumber === 'one' && '#2F4161',
        }}
      >
        <ListIcon isActive={rowsNumber === 'one'} />
      </button>
      <button
        onClick={() => onRowsClick('two')}
        className={styles.list_button}
        style={{
          backgroundColor: rowsNumber === 'two' && '#2F4161',
        }}
      >
        <ListLargeIcon isActive={rowsNumber === 'two'} />
      </button>
      <button
        className={styles.list_button}
        onClick={() => onRowsClick('three')}
        style={{
          backgroundColor: rowsNumber === 'three' && '#2F4161',
        }}
      >
        <GridIcon isActive={rowsNumber === 'three'} />
      </button>
    </div>
  )
}
