import React, { useState } from 'react'
import { pageSize } from 'shared/utils/pagination'
import Loader from 'components/shared/loader/Loader'
import { usePagedServices } from 'utils/serviceUtils'
import Pagination from 'components/shared/pagination/Pagination'
import { usePagedProductCatalogues } from 'utils/productCatalogueUtils'

import EmptyTab from '../EmptyTab'
import Tabs from '../Tabs'
import ProductCatalogueList from 'components/shared/list/ProductCatalogueList'
import ServiceList from 'components/shared/list/ServicesList'
import styles from './Catalogue.module.css'

interface ProductCataloguesTabProps {
  params?: any
}

const ProductCataloguesTab = ({ params }: ProductCataloguesTabProps) => {
  const [page, setPage] = useState(0)
  const productCatalogues = usePagedProductCatalogues({
    page,
    size: pageSize,
    ...params,
  })

  if (productCatalogues.error) {
    return <Loader />
  }
  if (productCatalogues.status === 'loading') {
    return <Loader />
  }

  if (productCatalogues.data.total === 0) {
    return <EmptyTab text="Nema proizvoda" />
  }
  return (
    <>
      <input
        type="text"
        className={styles.input}
        placeholder="Naziv proizvoda"
      />
      <ProductCatalogueList
        data={productCatalogues.data?.page}
        showViewOptions={false}
      />
      {productCatalogues.data.total > pageSize && (
        <Pagination
          setActivePage={setPage}
          pageCount={productCatalogues.data?.total / pageSize}
        />
      )}
    </>
  )
}

interface ServicesTabProps {
  params?: any
}

const ServicesTab = ({ params }: ServicesTabProps) => {
  const [page, setPage] = useState(0)
  const services = usePagedServices({
    page,
    size: pageSize,
    ...params,
  })

  if (services.error) {
    return <Loader />
  }
  if (services.status === 'loading') {
    return <Loader />
  }

  if (services.data.total === 0) {
    return <EmptyTab text="Nema usluga" />
  }
  return (
    <>
      <input type="text" className={styles.input} placeholder="Naziv usluge" />
      <ServiceList data={services.data?.page} showViewOptions={false} />
      {services.data.total > pageSize && (
        <Pagination
          setActivePage={setPage}
          pageCount={services.data?.total / pageSize}
        />
      )}
    </>
  )
}

const Catalogue = ({ activeSubTab, setActiveSubTab, companyId }) => {
  const tabsItems = {
    aTab: (
      <ProductCataloguesTab
        params={{
          [`store.company.id`]: companyId,
        }}
      />
    ),
    bTab: (
      <ProductCataloguesTab
        params={{
          [`store.company.id`]: companyId,
          [`product.status`]: 'PROMOTED',
        }}
      />
    ),
    cTab: (
      <ProductCataloguesTab
        params={{
          [`store.company.id`]: companyId,
          [`onSale`]: true,
        }}
      />
    ),

    dTab: (
      <ServicesTab
        params={{
          companyId: companyId,
        }}
      />
    ),
  }

  return (
    <div>
      <Tabs
        active={activeSubTab}
        onChange={(activeSubTab) => setActiveSubTab(activeSubTab)}
      >
        <p key="aTab">Proizvodi</p>
        <p key="bTab">Istaknuti proizvodi</p>
        <p key="cTab">Akcije</p>
        <p key="dTab">Usluge</p>
      </Tabs>
      <div>{tabsItems[activeSubTab]}</div>
    </div>
  )
}

export default Catalogue
