import React, { useState } from 'react'
import { groupBy } from 'lodash'
import { CompanyDto } from 'types/dto'
import down from 'assets/images/icons/down.svg'
import up from 'assets/images/icons/up.svg'
import Parser from 'html-react-parser'
import Map from 'components/shared/map/Map'
import validateVideoUrl from 'shared/utils/validateVideoUrl'
import cx from 'classnames'
import styles from './AboutCompany.module.css'

const Description = ({ text }) => {
  const showToggle = text.length > 350
  const [fullText, toggle] = useState(showToggle ? false : true)
  const descClasses = cx(styles.description, fullText && styles.fullDescription)
  const gradientClasses = cx(
    styles.gradient,
    fullText && styles.gradient_hidden
  )
  return (
    <div className={styles.descriptionContainer}>
      <div className={descClasses}>
        {Parser(text)}
        <div className={gradientClasses}></div>
      </div>

      {showToggle && (
        <div style={{ alignSelf: 'center', marginTop: '20px' }}>
          {fullText ? (
            <img src={up} alt="less" onClick={() => toggle(false)} />
          ) : (
            <img
              src={down}
              alt="more"
              style={{ alignSelf: 'center' }}
              onClick={() => toggle(true)}
            />
          )}
        </div>
      )}
    </div>
  )
}

const formatWorkingHours = (
  workingHours: CompanyDto['workingHours']
): string => {
  const groupedDays = groupBy(
    workingHours.map((day) => ({
      day: day.dayOfWeek,
      time: `${day.from.slice(0, -3)} - ${day.to.slice(0, -3)}h`,
    })),
    'time'
  )

  const keys = Object.keys(groupedDays)

  return keys
    .map((key) => {
      const days = groupedDays[key]
      if (days.length > 1)
        return `${days[0].day.slice(0, 3)} - ${days[days.length - 1].day.slice(
          0,
          3
        )} : ${key}`

      return `${days[0].day.slice(0, 3)} : ${key}`
    })

    .join(', ')
}

interface Props {
  company: CompanyDto
}

const AboutCompany: React.FC<Props> = ({ company }: Props) => {
  return (
    <div className={styles.about_company}>
      <div className={styles.about_company__info}>
        <div className={styles.working_hours}>
          <h3>RADNO VREME:</h3>
          <p>{formatWorkingHours(company.workingHours)}</p>
        </div>
        <div className={styles.phone}>
          <h3>KONTAKT TELEFON:</h3>
          <p>{company.phoneNum}</p>
        </div>
      </div>
      <div className={styles.desc_label}>
        <h3>Opis</h3>
      </div>
      <Description text={company.description} />
      <div id="companyMap" className={styles.map_location}>
        <h3>Lokacija</h3>
        <Map data={[company]} />
      </div>
      {validateVideoUrl(company.videoUrl) && (
        <div className={styles.promo_video}>
          <h3>Promo video</h3>
          <div className={styles.video_wrapper}>
            <iframe
              title={company.name}
              width="100%"
              height="400"
              src={company.videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutCompany
