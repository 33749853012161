import React from 'react'
import styles from './PrivacyAgreement.module.css'

const PrivacyAgreement = () => {
  return (
    <div className={`${styles.PrivacyAgreement} global-container`}>
      <h2>Politika privatnosti</h2>
      <p>
        Politika privatnosti je kreirana da bi Vam bolje objasnila na koji način
        se prikupljaju i čuvaju podaci na sajtu esnaf.rs.
      </p>
      <p>
        Svi podaci koje ostavite na ovom sajtu ni na koji način neće biti
        zloupotrebljeni niti će biti prepušteni trećem licu.
      </p>
      <p>
        Podatke koje ostavite na sajtu poštujemo i biće korišćeni isključivo za
        poboljšanje vašeg iskustva na sajtu. Korišćenjem ovog sajta prihvatate i
        politiku privatnosti.
      </p>
      <h3>ŠTA SU SVE LIČNI PODACI?</h3>
      <p>
        Lični podaci su svi oni podaci na osnovu kojih je moguće identifikovati
        pojedinačnu osobu. Do sada su lični podaci koje ste ostavljali na sajtu
        bili vaše ime i e-mail adresa i to samo u slučaju da ste nam slali
        poruku putem kontakt forme ili komentar na neku objavu. U momentu kada
        dođe do prodaje nekih proizvoda na ovom sajtu, od vas će biti tražena i
        kućna adresa, kao i broj telefona, iz razloga da bismo znali lokaciju na
        koju treba da vam pošaljemo proizvod.
      </p>
      <p>
        Sve te podatke dajete ukoliko želite da nas kontaktirate ili naručite
        neki proizvod, a za iste ćete biti pitani pre toga i podaci će biće
        korišćeni isključivo kako bi vam se odgovorilo na upiet ili poslala
        narudžbina.
      </p>
      <h3>
        KOJE LIČNE PODATKE PRIKUPLJAMO OD LJUDI KOJI POSEĆUJU OVAJ VEB PORTAL?
      </h3>
      <p>
        Ime, e-mail adresu, IP adresu korisnika. Ipak, sajt možete posetiti i
        anonimno preko skrivenih bowsera (Chrome incognito, Mozilla private i
        slično). Lične poratke kao što su email adresa i i ime ostavljate
        isključivo kada nas kontaktirate putem kontakt forme i ostavljate
        komentar na neki post.
      </p>
      <h3>KADA SAKUPLJAMO LIČNE INFORMACIJE?</h3>
      <p>
        Mi prikupljamo podatke od vas kada ostavite komentar na sajtu ili
        posetite sajt.
      </p>
      <div className="TableWrapper">
        <p>Ukratko:</p>
        <ul>
          <li>
            Prilikom ostavljanja komentara na neki post: Email (uvek), Ime (ako
            želite), IP adresa (uvek)
          </li>
          <li>Prilikom posete sajtu: IP adresu</li>
          <li>
            Prilikom slanja Email poruke preko kontakt forme: Ime (uvek), Email
            (uvek) Detaljnije:
          </li>
        </ul>
      </div>
      <h3>KOMENTARI</h3>
      <p>
        Kada ostavite komentar na ovom sajtu, sakupljamo podatke prikazane u
        obrascu za komentare. Takođe IP adresu i User Agent vašeg veb pregledača
        za pomoć u otkrivanju nepoželjnih komentara.
      </p>
      <p>
        WordPress koristi Gravatar servis i prilikom ostavljanja komentara naš
        sajt će poslati Vaše podatke prikazane u obrascu servisu Gravatar kako
        bi proverio da li ga koristite. Nakon odobrenja komentara, Vaša slika
        profila koju ste vezali za Vaš Gravatar nalog će biti vidljiva na našem
        sajtu. Politiku privatnosti servisa Gravatar možete pročitati na
        sledećem linku – automattic.com/privacy
      </p>
      <h3>POSETA NAŠEM SAJTU</h3>
      <p>
        Prilikom posete našem sajtu snima se Vaša IP adresa od strane dva
        servisa: Google Analytics i WordPress dodatka koji je zadužen za
        bezbednost sajta. Google Analytics će Vaše podatke izbrisati iz baze u
        roku od 38 meseci. Mi ćemo obrisati Vašu IP adresu koja je prikupljena
        prilikom posete sajta u roku od 7 dana. Ukoliko Vaša IP adresa bude
        blokirana od strane našeg sistema zaštite, to znači da ste pokušali da
        nanesete štetu sajtu i u tom slučaju protiv Vas može biti podneta
        krivična prijava.
      </p>
      <h3>SLANJE PORUKE PREKO KONTAKT FORME</h3>
      <p>
        Kada nam šaljete poruku putem kontakt forme koja se nalazi na našem
        sajtu, čuvaju se samo Vaši podaci koje unesete u kontakt formu: Ime i
        email adresa.
      </p>
      <h3>ŠTA SU PODACI KOJI NISU LIČNE PRIRODE?</h3>
      <p>
        To su svi dodatni podaci koji se prikupljaju pri vašoj poseti sajtu, a
        koji služe poboljšanju usluga i personalizaciji Vašeg iskustva na ovom
        sajtu. Na primer, to su ime pretraživača, tip računara (mobilni, tablet,
        desktop), operativni sistem, Internet provajder koji koriste i druge
        slične informacije.
      </p>
      <p>
        Sve je to u skladu sa zakonima, i uvek se vodi računa o Všoj privatnosti
        i zaštiti svih tih Vaših podataka.
      </p>
      <h3>KAKO KORISTIMO VAŠE PODATKE?</h3>
      <p>
        Možemo da koristimo informacije koje sakupljamo od Vas kada nam šaljete
        poruke i komentarišete objave, odgovorite na istraživanje ili marketing
        komunikaciju, surfujete veb portalom ili koristite određene druge
        funkcije sajta.
      </p>
      <p>
        Sve informacije koje sakupljamo od Vas mogu se koristiti na jedan od
        sledećih načina:
      </p>
      <p>
        Da personalizujmo Vaše iskustvo na sajtu (Vaše informacije nam pomažu da
        bolje odgovorimo na Vaše individualne potrebe), Da poboljšamo našu veb
        stranicu, Za sprovođenje takmičenja, promocija, istraživanja i drugih
        sličnih funkcija na sajtu, Za zaštitu našeg sajta (Vaša IP adresa može
        biti blokirana ako pokušate da na bilo koji način nanesete bilo kakvu
        štetu veb portalu).
      </p>
      <h3>KAKO SE PRIKUPLJAJU VAŠI PODACI NA OVOM SAJTU?</h3>
      <div className="TableWrapper">
        <p>Podaci na ovom sajtu se prikupljaju i čuvaju putem:</p>
        <ul>
          <li>pomoću “kolačića” (cookies) na sajtu,</li>
          <li>putem Google Analytics alata,</li>
          <li>putem Facebook Pixel alata,</li>
          <li>putem kontakt forme i komentara.</li>
        </ul>
      </div>
      <p>
        Kada je reč o kontakt formi i ostavljanju komentara na sajtu, samim tim
        što ste ih ispunili (ime i email) Vi te podatke dajete na raspolaganje.
        Ti podaci će se koristiti samo u one svrhe za koje su ostavljeni: da vam
        odgovorimo na komentare i poruke koje ste nam slali i oni neće biti
        korišćeni za promocije i slanje poruka o novostima na sajtu, niti za
        ustupanje trećem licu. Naš sajt se ažurira svakodnevno i novosti na
        njemu možete pratiti posetom istog, o njima vas nećemo obaveštavati,
        osim na društvenim mrežama.
      </p>
      <p>
        Kolačići (cookies) se koriste na većini sajtova, pa tako i na ovom. Web
        stranice pomoću kolačića mogu zapamtiti vaše aktivnosti ili postavke i
        sledeći put kada posetite istu stranicu mogu ih automatski primeniti.
        Mogu se koristiti za skladištenje lozinki ili formi koje ste ispunili na
        našem sajtu (na primer kada upisujete svoje ime ili adresu na web
        stranicama). Generalno gledajući, svrha kolačića je dobronamerna, nisu
        štetni i ne mogu nositi viruse. Oni omogućavaju da imate bolje iskustvo
        prilikom surfovanja internetom.
      </p>
      <div className="TableWrapper">
        <h3>KORISTIMO KOLAČIĆE ZA:</h3>
        <ul>
          <li>
            Prikupljanje podatka o prometu sajta i interakcijama na lokaciji
            kako bismo u budućnosti ponudili bolje iskustvo na sajtu.
          </li>
          <li>
            Takođe, možemo koristiti pouzdane usluge nezavisnih proizvođača koji
            prate ove informacije u naše ime.
          </li>
        </ul>
      </div>
      <p>
        Pri ulasku na sajt pojaviće vam se opcija i obaveštenje o kolačićima. Na
        vama je da prihvatite ili ne.
      </p>
      <p>
        Možete da izaberete da Vas računar upozorava svaki put kada se preuzima
        kolačić ili možete da odaberete da isključite sve kolačiće. To radite
        kroz podešavanja pretraživača. Pošto je pregledač malo drugačiji,
        pogledajte pomoć u pregledaču da biste saznali ispravan način za izmenu
        kolačića.
      </p>
      <h3>DoubleClick DART Cookie</h3>
      <p>
        Google, kao prodavac treće strane, koristi kolačiće za prikazivanje
        oglasa na esnaf.rs.
      </p>
      <p>
        Korišćenje DART kolačića pomoću Google-a omogućuje prikazivanje oglasa
        svojim korisnicima na osnovu njihove posete esnaf.rs i drugim sajtovima
        na Internetu.
      </p>
      <p>
        Korisnici mogu da odustanu od korišćenja kolačića DART tako što će
        posetiti Google smernicu o zaštiti privatnosti i sadržaja na sledećoj
        URL adresi – https://google.com/privaci_ads.html
      </p>
      <p>
        Ovi oglasni serveri nezavisnih proizvođača ili ad mreže koriste
        tehnologiju za reklamu i linkove koji se pojavljuju na esnaf.rs šalju
        direktno u Vaš web pregledač. Oni automatski primaju Vašu IP adresu kada
        se to dogodi. Druge tehnologije (kao što su kolačići, JavaScript ili
        „web beacons“) takođe mogu da koriste oglasne mreže nezavisnih
        proizvođača da bi se izmerila efikasnost njihovih oglasa i / ili da se
        personalizuju reklamni sadržaji koje vidite.
      </p>
      <p>
        Mi dozvoljavamo nezavisnim kompanijama da služe oglase i / ili
        prikupljaju određene anonimne informacije kada posetite našu veb
        stranicu. Ove kompanije mogu da koriste informacije koje nisu ličnog
        karaktera (npr. informacije o toku posete sajta, tip pretraživača, vreme
        i datum, vrsta reklamnih poruka koje su kliknute ili prikazane) tokom
        Vaših poseta ovoj i drugim veb lokacijama kako bi se obezbedila reklama
        o robi i uslugama koja je većeg interesa za Vas. Ove kompanije obično
        koriste kolačić ili „web beacon“ treće strane za prikupljanje ovih
        informacija. Da biste saznali više o ovoj praksi oglašavanja, ili da
        biste se odjavili od ove vrste oglašavanja, možete posetiti
        netvorkadvertising.org.
      </p>
      <p>
        Portal esnaf.rs nema pristup ili kontrolu nad ovim kolačićima koje
        koriste oglašivači trećih strana.
      </p>
      <p>
        Možete se konsultovati sa odgovarajućim politikama privatnosti tih
        oglasnih servera nezavisnih proizvođača za detaljnije informacije o
        njihovoj praksi, kao i za uputstva o tome kako se odjaviti od određenih
        servisa. Politika privatnosti esnaf.rs se ne odnosi na, a ne možemo ni
        kontrolisati, aktivnosti tih drugih oglašivača ili veb sajtova.
      </p>
      <p>
        Ako želite da onemogućite kolačiće, to možete učiniti putem određenih
        opcija u veb pregledača. Detaljnije informacije o upravljanju kolačićima
        u određenim veb pregledačima mogu se naći na odgovarajućim veb
        lokacijama pregledača.
      </p>
      <h3>ODJAVLJIVANJE:</h3>
      <p>
        Korisnici mogu postaviti podešavanja Google oglasa pomoću stranice
        Google Ad Settings. Kao alternativu, možete se odjaviti tako što ćete
        posetiti stranicu Network Advertising Initiative Opt Out ili pomoću
        dodatka Google Analitics Opt Out za Vaš veb pregledač ili korišćenjem Ad
        Blockera na browseru koji koristite.
      </p>
      <h3>AKO KORISNICI ONEMOGUĆE KOLAČIĆE U SVOM PREGLEDAČU:</h3>
      <p>
        Ako isključite kolačiće, neke funkcije koje kreiraju veb stranicu možda
        neće funkcionisati ispravno. Neke od funkcija koje čine Vaše iskustvo na
        sajtu boljim možda neće funkcionisati pravilno.
      </p>
      <p>
        <span>Google Analytics</span> prikuplja podatke o broju korisnika koji
        su došli na ovaj sajt, koja je njihova lokacija, koliko su se zadržali
        na sajtu. On ne pokazuje ko su pojedinačne osobe koje su posetile sajt,
        niti postoji pristup podacima o pojedinačnim korisnicima.
      </p>
      <p>
        <span>Facebook Pixel</span> je Facebook-ov alat koji prikuplja podatke
        putem ulogovanih Facebook profila. Pristup podacima o pojedinačnim
        korisnicima nije dostupan.
      </p>
      <p>
        I jedni i drugi podaci služe za istu svrhu: da se vidi šta najčešće
        čitate, a sve to u cilju unapređenja korisničkog iskustva i sajta koji
        posećujete. Drugim rečima, da bismo saznali koje destinacije i koji
        sadržaj vas najviše interesuju.
      </p>
      <p>
        Kod nas na sajtu takođe možete vršiti pretragu letova i smeštaja, ali
        nakon prikaza rezultata, odabirom na željeni (smeštaj ili let) bićete
        preusmereni na neki od sajtova naših partnera gde možete izvršiti
        rezervacije smeštaja i kupovine avio karata. Tom prilikom važi politika
        privatnosti sajta partnera na kom se nalazite i esnaf.rs ne prikuplja
        nikakve lične podatke. Naši partneri takođe poštuju politiku privatnosti
        i korišćenje njihovih sajtova je bezbedno, a u pitanju su provereni
        servisi. Svakako mi ne možemo snositi odgovornost za njihove postupke i
        molimo vas da ukoliko napustite stranicu esnaf.rs ili sa iste budete
        redirektovani na neku od stranica naših partnera proverite uslove
        korišćenja i politiku privatnosti.
      </p>
      <p>
        <span>Google oglasi</span> su prisutni na nekoliko mesta na našem sajtu
        i njihov izgled se jasno razlikuje od izgleda naših objava, možete ih
        prepoznati po tome što u gornjem desnom uglu imaju ikonice (i i X).
        Sadržaj koji vam se prikazuje na oglasima određuje google spram vašeg
        korisničkog iskustva i može biti često koristan za vas i kada kliknete
        na neki oglas bićete redirektovani na stranicu na kojoj možete videti
        više. Na toj stranici važe posebni uslovi korišćenja i politika
        privatnosti i esnaf.rs ne snosi odgovornost za iste. Prilikom korišćenja
        oglasa esnaf.rs ne prikuplja nikakve podatke o korisnicima.
      </p>
      <h3>KAKO SE ŠTITE VAŠI PODACI?</h3>
      <p>
        Ovaj sajt usvaja prikupljanje podataka, skladištenje, obradu i
        sigurnosne mere za zaštitu od neovlašćenog pristupa, promena,
        obelodanjivanja ili uništavanja ličnih informacija i podataka koji se
        nalaze na našem sajtu.
      </p>
      <p>
        Mi ne prodajemo, ne trgujemo ili na drugi način ne šaljemo trećim
        stranama Vaše lične informacije, osim ako ne pružimo unapred obaveštenje
        korisnicima. Ovo ne uključuje partnera za veb hosting i druge partnere
        koji pomažu u radu našeg veb sajta, vođenju našeg poslovanja ili
        pružanju usluga našim korisnicima, sve dok se te strane slažu da ove
        informacije budu poverljive. Takođe možemo objaviti informacije kada je
        to odgovarajuće za usklađivanje sa zakonom, sprovođenje naših politika
        na polju ili zaštiti naših ili drugih prava, imovine ili sigurnosti.
      </p>
      <h6>
        Mi objavljujemo članke i informacije i preko našeg sajta se ne vrše
        nikakva plaćanja, te stoga nikada ne tražimo bilo kakve informacije za
        plaćanje kao što su brojevi kreditnih kartica i slično.
      </h6>
      <p>
        Sajt je u skladu sa GDPR regulativom u cilju stvaranja što povoljnijeg i
        sigurnijeg ambijenta za vas.
      </p>
      <p>
        Sajt ne prodaje, trguje ili iznajmljuje vaše lične identifikacione
        podatke drugima.
      </p>
      <h3>POLITIKA PRIVATNOSTI ONLAJN</h3>
      <p>
        Ova onlajn politika privatnosti se odnosi samo na informacije
        prikupljene na našoj veb stranici, a ne i na informacije koje su
        prikupljene van mreže (offlajn).
      </p>
      <h3>UKLJUČEN SADRŽAJ SA DRUGIH SAJTOVA U NAŠ SAJT</h3>
      <p>
        Članci na ovom veb sajtu mogu da sadrže sadržaj (npr. video snimke,
        slike, članke, oglase itd). Uključen sadržaj sa drugih veb sajtova se
        ponaša na potpuno isti način kao da ste posetili to drugo veb mesto.
      </p>
      <p>
        Ta veb mesta mogu da prikupe podatke o Vama, koriste kolačiće, uključe
        dodatno praćenje od strane treće strane i nadgledaju Vašu interakciju sa
        tim uključenim sadržajem, uključujući praćenje vaše interakcije sa
        uključenim sadržajem ukoliko imate nalog i prijavljeni ste na to veb
        mesto.
      </p>
      <p>
        *Ako na primer delite sadržaj sa našeg sajta, on će biti objavljen na
        vašoj društvenoj mreži na kojoj ste podelili.
      </p>
      <h3>KOJA SU VAŠA PRAVA?</h3>
      <div className="TableWrapper">
        <p>U skladu sa GDPR regulativom vi imate pravo:</p>
        <ul>
          <li>
            da zatražite da pogledate koji su vaši podaci sačuvani u našoj bazi
            podataka
          </li>
          <li>
            da zatražite da budete zaboravljeni (da se izbrišu svi vaši podaci
            iz naše baze podataka)
          </li>
        </ul>
      </div>
      <h3>KAKO MOŽETE PRIHVATITI OVE USLOVE?</h3>
      <p>
        <span>Korišćenjem ovog sajta, prihvatate ovu politiku.</span> Ako se ne
        slažete sa ovom politikom, molim vas da ne koristite ovaj sajt.
        Korišćenje sajta nakon objavljivanja promena u ovoj politici će se
        smatrati kao vaše prihvatanje tih promena.
      </p>
      <p>
        Sajt ima diskreciono pravo da ažurira ovu politiku privatnosti u bilo
        koje vreme. Kada to učinimo, revidiraćemo ažurirani datum na dnu ove
        stranice.
      </p>
      <p>Ovaj dokument je ažuriran dana 02.09.2018. u skladu sa GDPR.</p>
    </div>
  )
}

export default PrivacyAgreement
