import React, { useState } from 'react'
import Parser from 'html-react-parser'
import { ProductCatalogueDto } from 'types/dto'
import Tabs from 'components/shared/tabs/Tabs'
import { useProductByIds } from 'utils/productUtils'
import Gallery from 'components/shared/tabs/categories/gallery/Gallery'
import validateVideoUrl from 'shared/utils/validateVideoUrl'
import EmptyTab from '../../components/shared/tabs/EmptyTab'
import ProductList from 'components/shared/list/ProductsList'

interface Props {
  productCatalogue: ProductCatalogueDto
}

const ProductCatalogueTabs: React.FC<Props> = ({ productCatalogue }) => {
  const [activeTab, setActiveTab] = useState('aTab')
  const relatedProducts = useProductByIds(
    productCatalogue.product.relatedProductIds
  )

  const { name, description, videoUrl, albumIds } = productCatalogue.product

  const tabsItems = {
    aTab: Parser(description || ''),
    bTab: <Gallery albumIds={albumIds} breadcrumbStart={name} />,
    dTab: <ProductList data={relatedProducts.data} />,
    eTab: validateVideoUrl(videoUrl) ? (
      <div>
        <iframe
          title={name}
          width="100%"
          height="400"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ) : (
      <EmptyTab text="Nema videa" />
    ),
  }
  return (
    <div>
      <Tabs
        active={activeTab}
        onChange={(activeTab) => setActiveTab(activeTab)}
      >
        <p key="aTab">DODATNI OPIS</p>
        <p key="bTab">GALERIJA</p>
        <p key="dTab">PROIZVODI IZ ISTE KATEGORIJE</p>
        <p key="eTab">VIDEO</p>
      </Tabs>
      <div>{tabsItems[activeTab]}</div>
    </div>
  )
}

export default ProductCatalogueTabs
