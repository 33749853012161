import { useQuery } from 'react-query'
import {
  fetchProductById,
  fetchProductByIds,
  fetchProducts,
} from 'services/productService'

export const usePagedProducts = (params: {
  page: number
  size: number
  sort?: string
  [key: string]: any
}) =>
  useQuery(
    ['products', params],
    () =>
      fetchProducts({
        params,
      }).then((data) => ({
        page: data.content,
        total: data.totalElements,
      })),
    { keepPreviousData: true }
  )

export const useProduct = (id: number) =>
  useQuery(['product', id], () => fetchProductById(id), { enabled: !!id })

export const useProductByIds = (ids: number[]) =>
  useQuery(['products', ids], () => fetchProductByIds(ids), { enabled: !!ids })
