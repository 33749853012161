import axios from 'axios'

export const apiUrl = process.env.REACT_APP_ESNAF_MS
export const mailApiUrl = process.env.REACT_APP_ESNAF_MAIL_MS

const http = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})
export default http
