import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { noop } from 'lodash'
import styles from './MoreOptions.module.css'

const MoreOptionsControl = ({ visible = false, toggle = noop }) => {
  return visible ? (
    <button className={styles.moreOptions} onClick={() => toggle(false)}>
      <FontAwesomeIcon
        icon={faMinusCircle}
        size="1x"
        style={{
          marginRight: '7px',
        }}
      />
      <span>Manje opcija</span>
    </button>
  ) : (
    <button className={styles.moreOptions} onClick={() => toggle(true)}>
      <FontAwesomeIcon
        icon={faPlusCircle}
        size="1x"
        style={{
          marginRight: '7px',
        }}
      />
      <span>Više opcija</span>
    </button>
  )
}

const optionsData = [
  {
    name: 'akcija',
    label: 'Na akciji',
  },
  {
    name: 'istaknuti',
    label: 'Istaknuti',
  },
  {
    name: 'opcija3',
    label: 'Opcija 3',
  },
  {
    name: 'opcija4',
    label: 'Opcija 4',
  },
]

const Options: React.FC = () => {
  return (
    <div className={styles.OptionsContainer}>
      {optionsData.map((option) => (
        <div className={styles.Option}>
          <input type="checkbox" name={option.name} value={option.name} />
          <span>{option.label}</span>
        </div>
      ))}
    </div>
  )
}

const MoreOptions: React.FC = ({ children }) => {
  const [moreOptions, toggleOptions] = useState(false)

  return (
    <>
      <div className={styles.controls}>
        <MoreOptionsControl visible={moreOptions} toggle={toggleOptions} />
        {children}
      </div>
      {moreOptions && <Options />}
    </>
  )
}

export default MoreOptions
