import React from 'react'
import logo from 'assets/images/icons/logo-esnaf.svg'

const UnderConstructionScreen: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
      }}
    >
      <img
        src={logo}
        style={{
          width: '10%',
          marginBottom: '20px',
        }}
        alt="Esnaf"
      />
      <h3
        style={{
          color: '#2f4161',
        }}
      >
        Under construction!
      </h3>
    </div>
  )
}

export default UnderConstructionScreen
