import React from 'react'
import { useParams } from 'react-router'
import Tabs from '../../Tabs'
import Loader from '../../../loader/Loader'
import { useCompany, useCompaniesByIds } from 'utils/companyUtils'
import EmptyTab from '../../EmptyTab'
import CompanyList from 'components/shared/list/CompaniesList'
import styles from './Associates.module.css'

const Associates = (props) => {
  const { activeSubTab, setActiveSubTab } = props
  const { id } = useParams()
  const company = useCompany(id)
  const distributors = useCompaniesByIds(company.data?.distributorIds)
  const manufacturers = useCompaniesByIds(company.data?.manufacturerIds)
  const partners = useCompaniesByIds(company.data?.partnerIds)
  // const retailers = useCompaniesByIds(company.data?.retailerIds)

  if (company.status === 'error') {
    //TODO return Not Found
    return <Loader />
  }

  if (company.status === 'loading') {
    return <Loader />
  }

  const tabsItems = {
    aTab:
      partners.data?.all.length > 0 ? (
        <div className={styles.wrapper}>
          <h3>Partneri</h3>
          <CompanyList data={partners.data?.all} showViewOptions={false} />
        </div>
      ) : (
        <EmptyTab text="Nema partnera" />
      ),

    bTab:
      distributors.data?.all.length > 0 ? (
        <div className={styles.wrapper}>
          <h3>Distributeri</h3>
          <CompanyList data={distributors.data?.all} showViewOptions={false} />
        </div>
      ) : (
        <EmptyTab text="Nema distributera" />
      ),
    cTab:
      manufacturers.data?.all.length > 0 ? (
        <div className={styles.wrapper}>
          <h3>Proizvođači</h3>
          <CompanyList data={manufacturers.data?.all} showViewOptions={false} />
        </div>
      ) : (
        <EmptyTab text="Nema proizvođača" />
      ),
  }

  return (
    <>
      <Tabs
        active={activeSubTab}
        onChange={(activeSubTab) => setActiveSubTab(activeSubTab)}
      >
        <p key="aTab">Partneri</p>
        <p key="bTab">Distributeri</p>
        <p key="cTab">Proizvodjaci</p>
      </Tabs>
      <div>{tabsItems[activeSubTab]}</div>
    </>
  )
}

export default Associates
