import { join } from 'lodash'
import { Page, StoreDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchStores = async (params = {}) => {
  const url = apiUrl + '/store/findAll'
  const { data } = await http.get<Page<StoreDto>>(url, params)
  return data
}

export const fetchStoreById = async (id: string | number) => {
  const url = apiUrl + '/store/findById'

  const { data } = await http.get<StoreDto>(url, {
    params: { id },
  })

  return data
}

export const fetchStoresByIds = async (ids: number[]) => {
  const url = apiUrl + '/product/findByIds'

  const { data } = await http.get<StoreDto[]>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}
