import React from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { useCompanies } from 'utils/companyUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import Select from 'components/shared/select/Select'
import Range from 'components/forms/rangeSlider/Range'
import { CategoryDto, CompanyDto, SubCategoryDto } from 'types/dto'
import { useCategories, useSubCategories } from 'utils/categoryUtils'
import { useProductRange } from 'utils/productCatalogueUtils'
import Loader from 'components/shared/loader/Loader'
import styles from './ProductsForm.module.css'
import MoreOptions from '../moreOptions/MoreOptions'

interface FormData {
  category: CategoryDto
  company: CompanyDto
  subCategory: SubCategoryDto
  search: string
  range: number[]
}

const ProductsForm = () => {
  const { params } = useURLQuery()
  const history = useHistory()
  const categories = useCategories()
  const subCategories = useSubCategories()
  const companies = useCompanies()
  const priceRange = useProductRange()

  const { register, handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      search: params.search || null,
      company: companies.data?.byId[params.company] || null,
      category: categories.data?.byId[params.category] || null,
      subCategory: subCategories.data?.byId[params.subCategory] || null,
    },
  })
  const onSubmit = handleSubmit(
    ({ search, company, category, range, subCategory }) => {
      history.push({
        pathname: '/proizvodi',
        search: `?${search ? `search=${search}&` : ''}${
          company ? `company=${company.id}&` : ''
        }${category ? `category=${category.id}&` : ''}${
          subCategory ? `subCategory=${subCategory.id}&` : ''
        }${range[0] ? `priceFrom=${range[0]}&` : ''}${
          range[1] ? `priceTo=${range[1]}&` : ''
        }`,
      })
    }
  )

  const category = watch('category')

  if (priceRange.status === 'loading') {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Pretraga proizvoda</h4>
      <form onSubmit={onSubmit}>
        <div className={styles.upper}>
          <Controller
            name="company"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Odaberite kompaniju"
                options={companies.data?.all}
                selectValue={value}
                setSelectValue={(option: CompanyDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Odaberite kategoriju"
                options={categories.data?.all}
                selectValue={value}
                setSelectValue={(option: CategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
          <Controller
            name="subCategory"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Odaberite podkategoriju"
                options={
                  category ? subCategories.data?.byCategoryId(category.id) : []
                }
                selectValue={value}
                setSelectValue={(option: SubCategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
        </div>

        <div className={styles.lower}>
          <Controller
            name="range"
            control={control}
            defaultValue={[
              priceRange.data?.lowestPrice,
              priceRange.data?.highestPrice,
            ]}
            render={({ onChange, value }) => (
              <div className={styles.slidecontainer}>
                <Range
                  onChange={onChange}
                  value={value}
                  max={priceRange.data?.highestPrice}
                  min={priceRange.data?.lowestPrice}
                />
              </div>
            )}
          />
          <input
            name="search"
            type="text"
            className={styles.input}
            placeholder="Naziv proizvoda, brend...."
            ref={register}
          />
          <input type="submit" className={styles.findButton} value="Traži" />
        </div>
      </form>
      <MoreOptions />
    </div>
  )
}

export default ProductsForm
