import { CategoryDto, SubCategoryDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchCategories = async () => {
  const url = apiUrl + '/category/findAll'
  const { data } = await http.get<{ content: CategoryDto[] }>(url)
  return data.content
}

export const fetchCategoryById = async (id: string | number) => {
  const url = apiUrl + '/category/findById'

  const { data } = await http.get<CategoryDto>(url, {
    params: { id },
  })

  return data
}

export const fetchSubCategories = async () => {
  const url = apiUrl + '/subCategory/findAll'
  const { data } = await http.get<{ content: SubCategoryDto[] }>(url)
  return data.content
}

export const fetchSubCategoryById = async (id: string | number) => {
  const url = apiUrl + '/subCategory/findById'

  const { data } = await http.get<SubCategoryDto>(url, {
    params: { id },
  })

  return data
}
