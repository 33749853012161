import React, { useState } from 'react'
import ItemLink from '../itemLink/ItemLink'
import { ServiceDto } from 'types/dto'
import Select from 'components/shared/select/Select'
import { useCompanies } from 'utils/companyUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import { routePaths } from 'routes/routes'
import { LayoutPicker, useLayoutPicker } from './layoutPicker/LayoutPicker'
import styles from './List.module.css'

interface Props {
  data: ServiceDto[]
  initialRowsNumber?: 'one' | 'two' | 'three'
  showSortOptions?: boolean
  showViewOptions?: boolean
}

const sortOptions = [
  { id: 1, name: 'Naziv usluge A-Z', value: 'name,ASC' },
  { id: 2, name: 'Naziv usluge Z-A', value: 'name,DESC' },
  { id: 3, name: 'Cena rastuće', value: 'price,ASC' },
  { id: 4, name: 'Cena opadajuće', value: 'price,DESC' },
]

const ServiceList: React.FC<Props> = ({
  data,
  initialRowsNumber = 'three',
  showSortOptions = false,
  showViewOptions = true,
}) => {
  const layoutPicker = useLayoutPicker({
    initialRowsNumber,
  })
  const companies = useCompanies()
  const { params, setParam } = useURLQuery()
  const [sortBy, setSortBy] = useState(
    sortOptions.find((option) => option.value === params.sort)
  )

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        {data && (
          <>
            {showSortOptions && (
              <span className={styles.header_left}>
                <>
                  <span>Poredjaj po:</span>
                  <Select
                    options={sortOptions}
                    selectValue={sortBy}
                    setSelectValue={(sortBy) => {
                      setSortBy(sortBy)
                      setParam('sort', sortBy?.value ? sortBy?.value : '')
                    }}
                  />
                </>
              </span>
            )}
            {showViewOptions && (
              <LayoutPicker
                rowsNumber={layoutPicker.rowsNumber}
                onRowsClick={layoutPicker.onRowsClick}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.items}>
        {data?.map((product, index) => {
          const { id, name, coverImageUrl, companyId, price } = product
          return (
            <div key={id} className={layoutPicker.itemClasses}>
              <ItemLink
                index={index}
                title={name}
                subtitle={companies.data?.byId[companyId].name}
                featured={false}
                image={coverImageUrl}
                premium={false}
                description={price.toString()}
                withShadow={true}
                link={routePaths.SERVICE.replace(':id', id.toString())}
                inRow={layoutPicker.rowsNumber === 'one'}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ServiceList
