import React from 'react'
import Loader from '../../components/shared/loader/Loader'
import { NavLink } from 'react-router-dom'
// import Ratings from '../../components/shared/ratings/Rating'
import { routePaths } from '../../routes/routes'
import { useParams } from 'react-router'
import { useCompany } from 'utils/companyUtils'
import Parser from 'html-react-parser'
import { useService } from 'utils/serviceUtils'
import ServiceTabs from './ServiceTabs'
import styles from '../productScreen/ProductScreen.module.css'
import BackButton from 'components/shared/backButton/BackButton'

const ServiceScreen = () => {
  const { id } = useParams()
  const service = useService(id)

  const manufacturer = useCompany(service.data?.companyId)

  if (service.status === 'error') {
    //TODO return  Not Found
    return <Loader />
  }

  if (service.status === 'loading') {
    return <Loader />
  }

  const { name, description, companyId, coverImageUrl } = service.data

  return (
    <div className={`${styles.singleProductScreen} global-container`}>
      <div className={styles.product_header}>
        <div className={styles.product_header__left}>
          <BackButton />
          <img
            className={styles.product_header_image}
            src={coverImageUrl}
            alt=""
          />
        </div>
        <div className={styles.product_header_content}>
          <div className={styles.product_title}>
            <div>
              <h3>{name}</h3>
              <NavLink
                to={routePaths.COMPANY.replace(':id', companyId.toString())}
              >
                <p>{manufacturer.data?.name}</p>
              </NavLink>
              {/* <Ratings initialRating={rating.average} /> */}
            </div>
          </div>

          <div className={styles.product_decription}>{Parser(description)}</div>
        </div>
      </div>
      <ServiceTabs service={service.data} />
    </div>
  )
}

export default ServiceScreen
