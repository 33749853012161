import React, { useState } from 'react'
import left from '../../../assets/images/icons/left.svg'
import right from '../../../assets/images/icons/right.svg'
import Parser from 'html-react-parser'
import { ProductCatalogueDto } from 'types/dto'
import styles from './SingleItemSlider.module.css'

interface Props {
  data?: ProductCatalogueDto[]
}

const SingleItemSlider: React.FC<Props> = ({ data }) => {
  const [visibleSlideIndex, setVisibleSlideIndex] = useState(0)

  let x
  let y

  const handleArrowPress = (direction) => {
    const isOnStart = visibleSlideIndex === 0
    const isOnEnd = visibleSlideIndex === data.length - 1
    if (direction === 'left') {
      setVisibleSlideIndex(isOnStart ? data.length - 1 : visibleSlideIndex - 1)
    } else {
      setVisibleSlideIndex(isOnEnd ? 0 : visibleSlideIndex + 1)
    }
  }

  const handleTouchStart = (event) => {
    x = event.touches[0].clientX
    y = event.touches[0].clientY
  }

  const handleTouchMove = (event) => {
    const xM = event.changedTouches[0].clientX
    const yM = event.changedTouches[0].clientY
    const xDiff = xM - x
    const yDiff = yM - y
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        handleArrowPress('left')
      } else {
        handleArrowPress('right')
      }
    }
    x = null
    y = null
  }

  const translateX = `${-100 * visibleSlideIndex}%`

  return (
    <div className={styles.singleSlider_wrapper}>
      <div
        style={{
          transform: `translateX(${translateX})`,
        }}
        className={styles.slider}
        onTouchStart={(event) => handleTouchStart(event)}
        onTouchEnd={(event) => handleTouchMove(event)}
      >
        {data?.map((item, index) => (
          <div className={styles.slideContainer} key={index}>
            <div className={styles.slider_info}>
              <div className={styles.info_upper}>
                <h3>{item.product.name}</h3>
                <p>{item.discountPrice}</p>
              </div>
              <div className={styles.info_lower}>
                {Parser(item.discountDescription || '')}
              </div>
            </div>
            <img
              className={styles.slide}
              src={item.product.coverImageUrl}
              alt="productCoverImage"
              key={index}
            />
          </div>
        ))}
      </div>
      <img
        className={styles.arrowLeft}
        onClick={() => handleArrowPress('left')}
        src={left}
        alt="arrowLeft"
      />
      <img
        className={styles.arrowRight}
        onClick={() => handleArrowPress('right')}
        src={right}
        alt="arrowRight"
      />
    </div>
  )
}

export default SingleItemSlider
