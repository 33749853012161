import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import { fetchAlbumsByIds } from '../services/albumService'

export const useAlbums = (ids: number[] = []) =>
  useQuery(
    ['albums', ids],
    () =>
      fetchAlbumsByIds(ids).then((albums) => ({
        all: albums,
        byId: keyBy(albums, 'id'),
      })),
    { enabled: ids !== [] }
  )

export const useCoverAlbum = (id: number) =>
  useQuery(
    ['coverAlbum', id],
    () =>
      fetchAlbumsByIds([id]).then((albums) => ({
        images: albums[0].images,
      })),
    {
      enabled: !!id,
    }
  )
