import React from 'react'
import ItemLink from '../itemLink/ItemLink'
import { ProductCatalogueDto } from 'types/dto'
import Select from 'components/shared/select/Select'
import { noop } from 'lodash'
import { routePaths } from 'routes/routes'
import { LayoutPicker, useLayoutPicker } from './layoutPicker/LayoutPicker'

import styles from './List.module.css'

interface Props {
  data: ProductCatalogueDto[]
  initialRowsNumber?: 'one' | 'two' | 'three'
  sortBy?: string
  setSortBy?: any
  showSortOptions?: boolean
  showViewOptions?: boolean
}
const sortOptions = [
  { id: 1, name: 'Naziv proizvoda A-Z', value: 'nameAsc' },
  { id: 2, name: 'Naziv proizvoda Z-A', value: 'nameDesc' },
  { id: 3, name: 'Cena rastuće', value: 'priceAsc' },
  { id: 4, name: 'Cena opadajuće', value: 'priceDesc' },
]

const ProductCatalogueList: React.FC<Props> = ({
  data,
  initialRowsNumber = 'three',
  sortBy = null,
  setSortBy = noop,
  showSortOptions = false,
  showViewOptions = true,
}) => {
  const layoutPicker = useLayoutPicker({
    initialRowsNumber,
  })

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        {data && (
          <>
            {showSortOptions && (
              <span className={styles.header_left}>
                <>
                  <span>Poredjaj po:</span>
                  <Select
                    options={sortOptions}
                    selectValue={sortBy}
                    setSelectValue={setSortBy}
                    customStyles={styles.select}
                  />
                </>
              </span>
            )}
            {showViewOptions && (
              <LayoutPicker
                rowsNumber={layoutPicker.rowsNumber}
                onRowsClick={layoutPicker.onRowsClick}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.items}>
        {data?.map((productCatalogue, index) => {
          const { id, price, discountPrice, product } = productCatalogue
          return (
            <div key={id} className={layoutPicker.itemClasses}>
              <ItemLink
                index={index}
                title={product.name}
                subtitle={`${price} RSD` || ''}
                featured={false}
                image={product.coverImageUrl}
                premium={false}
                description={discountPrice ? `${discountPrice} RSD` : ''}
                withShadow={true}
                link={routePaths.PRODUCT_CATALOGUE.replace(
                  ':id',
                  id.toString()
                )}
                inRow={layoutPicker.rowsNumber === 'one'}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductCatalogueList
