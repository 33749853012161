import { keyBy, map } from 'lodash'
import { useQuery } from 'react-query'
import { fetchBanners } from '../services/bannerService'
import { useCompaniesByIds } from 'utils/companyUtils'

export const useBanners = (ids: number[] = []) => {
  const bannerQuery = useQuery(
    ['banners'],
    () =>
      fetchBanners().then((banners) => ({
        // byPosition: keyBy(banners, 'bannerPosition'),
        all: banners,
        companyIds: map(banners, 'companyId'),
      })),
    {
      enabled: ids !== [],
    }
  )

  const companyQuery = useCompaniesByIds(bannerQuery.data?.companyIds)

  const bannersWithCompanies = bannerQuery.data?.all.map((rawBanner) => ({
    ...rawBanner,
    company: companyQuery.data?.byId[rawBanner.companyId],
  }))

  return {
    loading:
      bannerQuery.status === 'loading' || companyQuery.status === 'loading',
    byPosition: keyBy(bannersWithCompanies, 'bannerPosition'),
  }
}
