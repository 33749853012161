import React, { useState } from 'react'
import { useParams } from 'react-router'
import CompanyHeader from 'components/company/companyHeader/CompanyHeader'
import CompanyBanner from 'components/company/companyBanner/CompanyBanner'
import CompanyDetails from 'components/company/companyDetails/CompanyDetails'
import Loader from 'components/shared/loader/Loader'
import { useCompany } from 'utils/companyUtils'
import styles from '../companyScreen/CompanyScreen.module.css'

const RepairmanScreen = () => {
  const { id } = useParams()
  const repairman = useCompany(id)
  const [activeTab, setActiveTab] = useState('aTab')
  const [activeSubTab, setActiveSubTab] = useState('aTab')

  if (repairman.status === 'error') {
    //TODO return Not Found
    return <Loader />
  }

  if (repairman.status === 'loading') {
    return <Loader />
  }

  return (
    <div>
      <CompanyHeader company={repairman.data} />
      <CompanyBanner albumId={repairman.data?.coverAlbumId} />
      <div className={`${styles.main_content} global-container`}>
        <CompanyDetails
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          company={repairman.data}
        />
      </div>
    </div>
  )
}

export default RepairmanScreen
