import CompaniesForm from 'components/forms/company/CompaniesForm'
import Loader from 'components/shared/loader/Loader'
import Map from 'components/shared/map/Map'
import Pagination from 'components/shared/pagination/Pagination'
import React, { useState } from 'react'
import useURLQuery from 'shared/hooks/useURLQuery'
import { useCities } from 'utils/cityUtils'
import { usePagedCompanies } from 'utils/companyUtils'
import { pageSize } from 'shared/utils/pagination'
import styles from './CompaniesScreen.module.css'
import CompanyList from 'components/shared/list/CompaniesList'

const CompaniesScreen = () => {
  const { params } = useURLQuery()
  const cities = useCities()

  const [page, setPage] = useState(0)

  const companies = usePagedCompanies({
    isRepairman: false,
    name: params.search,
    'address.city': cities.data?.byId[params.city]?.name,
    'categories.id': params.category,
    'subCategories.id': params.subCategory,
    lat: params.lat,
    lon: params.lon,
    distance: params.distance,
  })

  if (companies.error) {
    return <Loader />
  }
  if (companies.status === 'loading') {
    return <Loader />
  }

  return (
    <div className={styles.companiesScreen}>
      <div className={styles.mapContainer}>
        <Map data={companies.data.page} />
      </div>
      <div className={styles.companiesListContainer}>
        <CompaniesForm />
        <div className={styles.companiesList}>
          <CompanyList
            data={companies.data?.page.slice(
              page * pageSize,
              (page + 1) * pageSize
            )}
          />
          <Pagination
            setActivePage={setPage}
            pageCount={companies.data?.total / pageSize}
          />
        </div>
      </div>
    </div>
  )
}

export default CompaniesScreen
