import React, { useState } from 'react'
import { useParams } from 'react-router'
import { HelpingStepDto } from 'types/dto'
import Picker from 'components/home/latest/Picker'
import Loader from 'components/shared/loader/Loader'
import { useProductByIds } from 'utils/productUtils'
import { useHelpingSteps } from 'utils/helpingUtils'
import { useCompaniesByIds } from 'utils/companyUtils'
import LatestProducts from 'components/home/latest/LatestProducts'
import LatestCompanies from 'components/home/latest/LatestCompanies'
import styles from './HowCanWeHelp.module.css'

interface StepListProps {
  step: HelpingStepDto
}
const StepList: React.FC<StepListProps> = ({ step }) => {
  const [activeCategory, setActiveCategory] = useState('company')
  const companies = useCompaniesByIds(step.companyIds)
  const products = useProductByIds(step.productIds)

  return (
    <div className={styles.SecondSection}>
      {activeCategory === 'company' && (
        <LatestCompanies companies={companies.data?.all} label={step.name}>
          <Picker
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </LatestCompanies>
      )}
      {activeCategory === 'product' && (
        <LatestProducts products={products.data} label={step.name}>
          <Picker
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </LatestProducts>
      )}
    </div>
  )
}

const HowCanWeHelpScreen: React.FC = () => {
  const { id } = useParams()

  const stepsQuery = useHelpingSteps()

  if (stepsQuery.status === 'error') {
    //TODO return  Not Found
    return <Loader />
  }

  if (stepsQuery.status === 'loading') {
    return <Loader />
  }

  return (
    <div className={styles.HowCanWeHelp_wrapper}>
      {stepsQuery.data?.bySegmentId(id).map((step) => (
        <StepList step={step} key={step.id} />
      ))}
    </div>
  )
}

export default HowCanWeHelpScreen
