import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import { fetchCities } from '../services/cityService'

export const useCities = () =>
  useQuery('cities', () =>
    fetchCities().then((cities) => ({
      all: cities,
      byId: keyBy(cities, 'id'),
    }))
  )
