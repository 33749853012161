import { join } from 'lodash'
import { CompanyDto, Page } from '../types/dto'
import http, { apiUrl, mailApiUrl } from './httpService'

export const fetchCompanies = async (params = {}) => {
  const url = apiUrl + '/company/findAll'
  const { data } = await http.get<Page<CompanyDto>>(url, params)
  return data
}

export const fetchCompanyById = async (id: string | number) => {
  const url = apiUrl + '/company/findById'

  const { data } = await http.get<CompanyDto>(url, {
    params: { id },
  })

  return data
}

export const fetchCompanyByIds = async (ids: string[] | number[]) => {
  const url = apiUrl + '/company/findByIds'

  const { data } = await http.get<CompanyDto[]>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}

interface NewCompanyRequest {
  name: string
  lastName: string
  email: string
  subject: string
  companyName: string
  pib: string
  uniCode: string
  phoneNumber: string
  postalCode: string
  city: string
  address: string
}
export const addNewCompany = (newCompany: NewCompanyRequest) =>
  http.post(mailApiUrl + '/mail/contact', newCompany)
