import React from 'react'
import Parser from 'html-react-parser'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'routes/routes'
import { useHelpingSegments } from 'utils/helpingUtils'
import styles from './HowCanWeHelp.module.css'

const HowCanWeHelp: React.FC = () => {
  const segments = useHelpingSegments()

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Kako možemo da vam pomognemo?</h4>
      <div className={`${styles.content} global-container`}>
        {segments.data?.all?.map((segment) => {
          return (
            <div className={styles.item} key={segment.id}>
              <NavLink
                className={styles.circle}
                to={routePaths.HOW_CAN_WE_HELP.replace(':id', segment.id)}
              >
                <img src={segment.iconUrl} alt="icon" />
                <p>{segment.name}</p>
              </NavLink>
              <h5 className={styles.item_title}>{segment.name}</h5>
              <span className={styles.item_desc}>
                {Parser(segment.description)}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HowCanWeHelp
