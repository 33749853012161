import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import {
  fetchHelpingSegments,
  fetchHelpingSteps,
} from '../services/helpingService'

export const useHelpingSegments = () =>
  useQuery('helpingSegments', () =>
    fetchHelpingSegments().then((segments) => ({
      all: segments,
      byId: keyBy(segments, 'id'),
    }))
  )

export const useHelpingSteps = () =>
  useQuery(['helpingSteps'], () =>
    fetchHelpingSteps().then((steps) => ({
      all: steps,
      bySegmentId: (id) =>
        steps.filter(
          (step) => step.helpingSegmentId.toString() === id.toString()
        ),
    }))
  )
