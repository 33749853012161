import React from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { useCities } from 'utils/cityUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import Select from 'components/shared/select/Select'
import { CategoryDto, City, SubCategoryDto } from 'types/dto'
import { useCategories, useSubCategories } from 'utils/categoryUtils'
import MoreOptions from '../moreOptions/MoreOptions'
import styles from '../product/ProductsForm.module.css'

interface FormData {
  search: string
  city: City
  category: CategoryDto
  subCategory: SubCategoryDto
}

const RepairmenForm = () => {
  const { params } = useURLQuery()
  const history = useHistory()
  const categories = useCategories()
  const subCategories = useSubCategories()
  const cities = useCities()

  const { register, handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      city: cities.data?.byId[params.city] || null,
      search: params.search,
      category: categories.data?.byId[params.category] || null,
      subCategory: subCategories.data?.byId[params.subCategory] || null,
    },
  })

  const onSubmit = handleSubmit(({ search, city, category, subCategory }) => {
    history.push({
      pathname: '/majstori',
      search: `?${search ? `search=${search}&` : ''}${
        city ? `city=${city.id}&` : ''
      }${category ? `category=${category.id}&` : ''}${
        subCategory ? `subCategory=${subCategory.id}&` : ''
      }`,
    })
  })

  const category = watch('category')

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Pronađite majstora</h4>
      <form onSubmit={onSubmit}>
        <div className={styles.upper}>
          <Controller
            name="city"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Svi gradovi"
                options={cities.data?.all}
                selectValue={value}
                setSelectValue={(option: City) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Sve kategorije"
                options={categories.data?.all}
                selectValue={value}
                setSelectValue={(option: CategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />

          <Controller
            name="subCategory"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Sve podkategorije"
                options={
                  category ? subCategories.data?.byCategoryId(category.id) : []
                }
                selectValue={value}
                setSelectValue={(option: SubCategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
        </div>
        <div className={styles.lower}>
          <input
            name="search"
            type="text"
            className={styles.altInput}
            placeholder="Traži..."
            ref={register}
          />
          <input type="submit" className={styles.findButton} value="Traži" />
        </div>

        <MoreOptions />
      </form>
    </div>
  )
}

export default RepairmenForm
