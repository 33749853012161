import React, { useState } from 'react'
import cx from 'classnames'
import { CompanyDto } from 'types/dto'
import { usePagedProductCatalogues } from 'utils/productCatalogueUtils'
import SingleItemSlider from 'components/shared/singleItemSlider/SingleItemSlider'
import styles from './CompanySidebar.module.css'

interface Props {
  setActiveTab(tab: string): void
  setActiveSubTab(tab: string): void
  company: CompanyDto
}

const CompanySidebar: React.FC<Props> = ({
  setActiveTab,
  setActiveSubTab,
  company,
}) => {
  const [isContactHidden, setContactVisibility] = useState(true)

  const productCataloguesSale = usePagedProductCatalogues({
    [`store.company.id`]: company.id,
    [`onSale`]: true,
  })
  const productCataloguesPromoted = usePagedProductCatalogues({
    [`store.company.id`]: company.id,
    [`status`]: 'PROMOTED',
  })

  const handleToggleContact = () => {
    setContactVisibility(!isContactHidden)
  }

  const contactWrapperClasses = cx(
    styles.contact_wrapper,
    isContactHidden && styles.contact_wrapper_hidden
  )

  return (
    <div className={styles.company_sidebar}>
      <button onClick={handleToggleContact}>E-mail</button>
      <div className={contactWrapperClasses}>
        <div className={styles.contact_header}>
          <img src={company.logoUrl} alt="logo" />
          <a
            href={company.webPageUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Kontakt - {company.name}
          </a>
        </div>
        <div className={styles.contact_form}>
          <input type="email" name="emailaddress" placeholder="Email adresa" />
          <input type="tel" name="phone" placeholder="Broj telefona" />
          <textarea name="comment" placeholder="Poštovani, ..." />
        </div>
        <div className={styles.contact_submit}>
          <button>Pošalji poruku</button>
        </div>
      </div>
      {productCataloguesPromoted.data?.page.length > 0 && (
        <div className={styles.singleItemSlider_container}>
          <h3
            onClick={() => {
              setActiveTab('cTab')
              setActiveSubTab('bTab')
            }}
          >
            Istaknuti proizvodi
          </h3>
          <SingleItemSlider data={productCataloguesPromoted.data?.page} />
        </div>
      )}
      {productCataloguesSale.data?.page.length > 0 && (
        <div className={styles.singleItemSlider_container}>
          <h3
            onClick={() => {
              setActiveTab('cTab')
              setActiveSubTab('cTab')
            }}
          >
            Akcije
          </h3>
          <SingleItemSlider data={productCataloguesSale.data?.page} />
        </div>
      )}
    </div>
  )
}

export default CompanySidebar
