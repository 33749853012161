import React, { useState } from 'react'
import { CompanyDto } from 'types/dto'
import { routePaths } from 'routes/routes'
import left from 'assets/images/icons/left.svg'
import right from 'assets/images/icons/right.svg'
import styles from './Latest.module.css'
import { getBreakpoint, MAX_VISIBLE_LINKS } from './helpers'
import ItemLink from 'components/shared/itemLink/ItemLink'

interface Props {
  label: string
  companies: CompanyDto[]
}

const LatestCompanies: React.FC<Props> = ({
  label,
  companies,
  children = null,
}) => {
  const [visibleCompanyIndex, setVisibleCompanyIndex] = useState(0)

  let x
  let y
  let xM
  let yM

  const handleSwipe = (direction) => {
    const MAX_LENGTH = companies?.length
    console.log('maxLen', MAX_LENGTH)
    const isOnStart = visibleCompanyIndex === 0
    console.log('isOnStart', isOnStart)

    const isOnEnd =
      visibleCompanyIndex === MAX_LENGTH - MAX_VISIBLE_LINKS[getBreakpoint()]
    if (direction === 'left') {
      setVisibleCompanyIndex(
        isOnStart
          ? MAX_LENGTH - MAX_VISIBLE_LINKS[getBreakpoint()]
          : visibleCompanyIndex - 1
      )
    } else {
      setVisibleCompanyIndex(isOnEnd ? 0 : visibleCompanyIndex + 1)
    }
  }

  const handleTouchStart = (event) => {
    x = event.touches[0].clientX
    y = event.touches[0].clientY
  }

  const handleTouchMove = (event) => {
    xM = event.changedTouches[0].clientX
    yM = event.changedTouches[0].clientY
    const xDiff = xM - x
    const yDiff = yM - y
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      handleSwipe(xDiff > 0 ? 'left' : 'right')
    }
    x = null
    y = null
  }

  const translateXSMValue = `${-105 * visibleCompanyIndex}%`
  const translateXMDValue = `${-50 * visibleCompanyIndex}%`
  const translateXLGValue = `${-33.3 * visibleCompanyIndex}%`
  const translateXValue = {
    sm: translateXSMValue,
    md: translateXMDValue,
    lg: translateXLGValue,
  }

  return (
    <div className={`${styles.LatestCompanies} global-container`}>
      <div className={styles.LatestCompanies_top}>
        <div>
          <h3 className={styles.LatestCompanies_title}>{label}</h3>
          {children}
        </div>
        {companies?.length > MAX_VISIBLE_LINKS[getBreakpoint()] && (
          <div className={styles.LatestCompanies_controls}>
            <img src={left} alt="left" onClick={() => handleSwipe('left')} />
            <img src={right} alt="right" onClick={() => handleSwipe('right')} />
          </div>
        )}
      </div>
      <div
        className={styles.LatestCompanies_bottom}
        style={{
          transform: `translateX(${translateXValue[getBreakpoint()]})`,
        }}
        onTouchStart={(event) => handleTouchStart(event)}
        onTouchEnd={(event) => handleTouchMove(event)}
      >
        {companies?.map((company, companyIndex) => {
          const { id, name, address, promoted, thumbnail } = company

          return (
            <div key={companyIndex} className={styles.LatestCompaniesItem}>
              <ItemLink
                index={companyIndex}
                key={id}
                title={name}
                subtitle={`${address.street}, ${address.city}`}
                withShadow={true}
                featured={promoted}
                image={thumbnail}
                premium={true}
                link={routePaths.COMPANY.replace(':id', id.toString())}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LatestCompanies
