import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import {
  fetchCompanies,
  fetchCompanyById,
  fetchCompanyByIds,
} from '../services/companyService'

export const useLatestCompanies = () =>
  useQuery('latestCompanies', () =>
    fetchCompanies({
      params: {
        isRepairman: false,
        sort: `id,desc`,
        size: 6,
      },
    }).then((companies) => ({
      all: companies.content,
      byId: keyBy(companies, 'id'),
    }))
  )

export const useCompanies = () =>
  useQuery('companies', () =>
    fetchCompanies().then((companies) => ({
      all: companies.content,
      byId: keyBy(companies.content, 'id'),
    }))
  )

export const usePagedCompanies = (params: {
  page?: number
  size?: number
  sort?: string
  isRepairman?: boolean
  [key: string]: any
}) =>
  useQuery(
    ['companies', params],
    () =>
      fetchCompanies({
        params,
      }).then((data) => ({
        page: data.content,
        total: data.totalElements,
      })),
    { keepPreviousData: true }
  )

export const useCompany = (id: number | string) =>
  useQuery(['company', id], () => fetchCompanyById(id), { enabled: !!id })

export const useCompaniesByIds = (ids: number[] | string[]) =>
  useQuery(
    ['companies', ids],
    () =>
      fetchCompanyByIds(ids).then((companies) => ({
        all: companies,
        byId: keyBy(companies, 'id'),
      })),
    { enabled: !!ids }
  )
