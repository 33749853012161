import React, { useState } from 'react'
import { useParams } from 'react-router'
import CompanyHeader from 'components/company/companyHeader/CompanyHeader'
import CompanyBanner from 'components/company/companyBanner/CompanyBanner'
import CompanyDetails from 'components/company/companyDetails/CompanyDetails'
import CompanySidebar from 'components/company/companySidebar/CompanySidebar'
import Loader from 'components/shared/loader/Loader'
import { useCompany } from 'utils/companyUtils'
import styles from './CompanyScreen.module.css'

const CompanyScreen = () => {
  const { id } = useParams()
  const company = useCompany(id)

  const [activeTab, setActiveTab] = useState('aTab')
  const [activeSubTab, setActiveSubTab] = useState('aTab')

  if (company.status === 'error') {
    //TODO return Not Found
    return <Loader />
  }

  if (company.status === 'loading') {
    return <Loader />
  }

  return (
    <div>
      <CompanyHeader company={company.data} />
      <CompanyBanner albumId={company.data?.coverAlbumId} />
      <div className={`${styles.main_content} global-container`}>
        <CompanyDetails
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          company={company.data}
        />
        <CompanySidebar
          company={company.data}
          setActiveSubTab={setActiveSubTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  )
}

export default CompanyScreen
