import { join } from 'lodash'
import { Page, ProductDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchProducts = async (params = {}) => {
  const url = apiUrl + '/product/findAll'
  const { data } = await http.get<Page<ProductDto>>(url, params)
  return data
}

export const fetchProductById = async (id: string | number) => {
  const url = apiUrl + '/product/findById'

  const { data } = await http.get<ProductDto>(url, {
    params: { id },
  })

  return data
}

export const fetchProductByIds = async (ids: number[]) => {
  const url = apiUrl + '/product/findByIds'

  const { data } = await http.get<ProductDto[]>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}
