import React from 'react'
import ItemLink from '../itemLink/ItemLink'
import { CompanyDto } from 'types/dto'
import { LayoutPicker, useLayoutPicker } from './layoutPicker/LayoutPicker'
import styles from './List.module.css'

interface Props {
  data: CompanyDto[]
  initialRowsNumber?: 'one' | 'two' | 'three'
  showViewOptions?: boolean
}

const RepairmenList: React.FC<Props> = ({
  data,
  initialRowsNumber = 'three',
  showViewOptions = true,
}) => {
  const layoutPicker = useLayoutPicker({
    initialRowsNumber,
  })

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        {showViewOptions && data && (
          <>
            <span className={styles.list_info}>
              {`Prikazano ${data.length} rezultata`}
            </span>
            <LayoutPicker
              rowsNumber={layoutPicker.rowsNumber}
              onRowsClick={layoutPicker.onRowsClick}
            />
          </>
        )}
      </div>
      <div className={styles.items}>
        {data?.map((company, index) => {
          const { id, name, address, promoted, thumbnail } = company

          return (
            <div key={id} className={layoutPicker.itemClasses}>
              <ItemLink
                index={index}
                key={id}
                title={name}
                subtitle={`${address.street}, ${address.city}`}
                withShadow={true}
                featured={promoted}
                image={thumbnail}
                premium={true}
                link={`majstor/${id}`}
                inRow={layoutPicker.rowsNumber === 'one'}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RepairmenList
