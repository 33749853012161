import React from 'react'
import facebook from 'assets/images/icons/facebook.svg'
import facebookBlue from 'assets/images/icons/facebookBlue.svg'
import instagram from 'assets/images/icons/instagram.svg'
import instagramBlue from 'assets/images/icons/instagramBlue.svg'
import pinterest from 'assets/images/icons/pinterest.svg'
import pinterestBlue from 'assets/images/icons/pinterestBlue.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import styles from './TopBar.module.css'

const TopBar: React.FC = () => (
  <div className={styles.Navigation_upper_container}>
    <div className={`${styles.Navigation_upper} global-container`}>
      <div className={styles.Navigation_upper__left}>
        <div className={styles.TopBar_info}>
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className={styles.TopBar_info_icon}
          />
          <a href="tel:+381 64 32 91 729">+381 64 32 91 729</a>
        </div>
        <div className={styles.TopBar_info}>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ fontSize: '15px' }}
            className={styles.TopBar_info_icon}
          />
          <a href="mailto:office@esnaf.rs">office@esnaf.rs</a>
        </div>
      </div>
      <div className={styles.Navigation_upper__right}>
        <div className={styles.TopBar_social}>
          <img src={facebook} alt="facebook" />
          <img src={facebookBlue} alt="facebook" />
        </div>
        <div className={styles.TopBar_social}>
          <img src={instagram} alt="instagram" />
          <img src={instagramBlue} alt="facebook" />
        </div>
        <div className={styles.TopBar_social}>
          <img src={pinterest} alt="pinterest" />
          <img src={pinterestBlue} alt="facebook" />
        </div>
      </div>
    </div>
  </div>
)

export default TopBar
