import React from 'react'
import styles from './NotFoundScreen.module.css'

const NotFoundScreen = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div>
        <p className={styles.notFoundText}>404 - Not Found</p>
      </div>
    </div>
  )
}

export default NotFoundScreen
