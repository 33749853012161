import React, { useState } from 'react'
import { CompanyDto } from 'types/dto'
import { usePagedStores } from 'utils/storeUtils'
import { pageSize } from 'shared/utils/pagination'
import Loader from 'components/shared/loader/Loader'
import AboutCompany from '../aboutCompany/AboutCompany'
import Pagination from 'components/shared/pagination/Pagination'
import Gallery from 'components/shared/tabs/categories/gallery/Gallery'
import Associates from 'components/shared/tabs/categories/associates/Associates'
import Catalogue from 'components/shared/tabs/catalogue/Catalogue'
import EmptyTab from '../../shared/tabs/EmptyTab'
import Tabs from 'components/shared/tabs/Tabs'
import styles from './CompanyDetails.module.css'
import StoresList from 'components/shared/list/StoresList'

const StoreTab = ({ params }: { params?: any }) => {
  const [page, setPage] = useState(0)
  const stores = usePagedStores({
    page,
    size: pageSize,
    ...params,
  })

  if (stores.error) {
    return <Loader />
  }
  if (stores.status === 'loading') {
    return <Loader />
  }

  if (stores.data.total === 0) {
    return <EmptyTab text="Nema prodavnica" />
  }

  return (
    <>
      <StoresList data={stores.data?.page} />
      {stores.data.total > pageSize && (
        <Pagination
          setActivePage={setPage}
          pageCount={stores.data?.total / pageSize}
        />
      )}
    </>
  )
}

interface Props {
  company: CompanyDto
  [key: string]: any
}

const CompanyDetails: React.FC<Props> = ({
  company,
  activeTab,
  setActiveTab,
  activeSubTab,
  setActiveSubTab,
}) => {
  const tabsItems = {
    aTab: <AboutCompany company={company} />,
    bTab: (
      <Gallery albumIds={company.albumIds} breadcrumbStart={company.name} />
    ),
    cTab: (
      <Catalogue
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
        companyId={company.id}
      />
    ),
    dTab: (
      <StoreTab
        params={{
          [`company.id`]: company.id,
        }}
      />
    ),
    eTab: (
      <Associates
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
      />
    ),
  }

  return (
    <div className={styles.companyDetails}>
      <Tabs
        active={activeTab}
        onChange={(activeTab) => setActiveTab(activeTab)}
      >
        <p key="aTab">O FIRMI</p>
        <p key="bTab">GALERIJA</p>
        <p key="cTab">KATALOG</p>
        <p key="dTab">PRODAJNA MESTA</p>
        <p key="eTab">SARADNICI</p>
      </Tabs>
      <div>{tabsItems[activeTab]}</div>
    </div>
  )
}

export default CompanyDetails
