import { uniqueId } from 'lodash'
import { City } from 'types/dto'

export const fetchCities = async () => {
  return [
    { id: uniqueId(), name: 'Beograd' },
    { id: uniqueId(), name: 'Novi sad' },
    { id: uniqueId(), name: 'Čačak' },
    { id: uniqueId(), name: 'Požarevac' },
  ] as City[]
}
