import React from 'react'
import { useForm } from 'react-hook-form'
import { addNewCompany } from 'services/companyService'
import styles from './AddCompanyScreen.module.css'

interface FormData {
  name: string
  lastName: string
  email: string
  subject: string
  companyName: string
  pib: string
  uniCode: string
  phoneNumber: string
  postalCode: string
  city: string
  address: string
}
const AddCompanyScreen = () => {
  const { register, handleSubmit } = useForm<FormData>()
  const onSubmit = handleSubmit((data) => addNewCompany(data))
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Dodajte firmu</h3>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          ref={register}
          name="companyName"
          className={styles.input}
          placeholder="Naziv kompanije"
        />
        <input
          type="text"
          ref={register}
          name="pib"
          className={styles.input}
          placeholder="PIB"
        />
        <input
          type="text"
          ref={register}
          name="uniCode"
          className={styles.input}
          placeholder="Matični broj"
        />
        <div className={styles.find}>
          <h3 className={styles.title}>Kontakt informacije</h3>
          <input
            type="text"
            ref={register}
            name="name"
            className={styles.input}
            placeholder="Ime"
          />
          <input
            type="text"
            ref={register}
            name="lastName"
            className={styles.input}
            placeholder="Prezime"
          />
          <input
            type="text"
            ref={register}
            name="email"
            className={styles.input}
            placeholder="Email"
          />
          <input
            type="text"
            ref={register}
            name="phoneNumber"
            className={styles.input}
            placeholder="Telefon"
          />
          <input
            type="text"
            ref={register}
            name="postalCode"
            className={styles.input}
            placeholder="Poštanski broj"
          />
          <input
            type="text"
            ref={register}
            name="city"
            className={styles.input}
            placeholder="Grad"
          />
          <input
            type="text"
            ref={register}
            name="address"
            className={styles.input}
            placeholder="Adresa"
          />
        </div>
        <div className="controls">
          <input type="submit" className="findButton" value="Dodajte" />
        </div>
      </form>
    </div>
  )
}

export default AddCompanyScreen
