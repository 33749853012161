import React from 'react'
import cx from 'classnames'
import styles from './Tabs.module.css'

interface TabsProps {
  active: string
  onChange(key: string)
  children: any
}

const handleScroll = () => {
  if (window.innerWidth > 1024) {
    const element = document.getElementById('tabs--container') as HTMLElement
    window.scrollTo({
      top: element.offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  }
}

const Tabs: React.FC<TabsProps> = ({ active, onChange, children }) => {
  return (
    <div className={styles.tabs}>
      <div id="tabs--container" className={styles.container}>
        {React.Children.map(children, (child) => {
          const tabsClasses = cx(
            styles.tab,
            child.key === active && styles.tab_active
          )
          return (
            <div
              className={tabsClasses}
              onClick={() => {
                onChange(child.key)
                handleScroll()
              }}
            >
              {child}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tabs
