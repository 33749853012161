import React from 'react'
import ItemLink from '../itemLink/ItemLink'
import { StoreDto } from 'types/dto'
import { useCompanies } from 'utils/companyUtils'
import { routePaths } from 'routes/routes'
import styles from './List.module.css'
import itemStyles from './layoutPicker/LayoutPicker.module.css'

interface Props {
  data: StoreDto[]
}

const StoresList: React.FC<Props> = ({ data }) => {
  const companies = useCompanies()
  return (
    <div className={styles.list}>
      <div className={styles.items}>
        {data?.map((store, index) => {
          const { id, name, address, companyId } = store

          return (
            <div key={id} className={itemStyles.threeRowItem}>
              <ItemLink
                index={index}
                key={id}
                title={name}
                subtitle={`${address.street}, ${address.city}`}
                withShadow={true}
                featured={false}
                image={companies.data?.byId[companyId].thumbnail} //add image to companies
                premium={true}
                link={routePaths.COMPANY.replace(':id', companyId.toString())}
                // inRow={true}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default StoresList
