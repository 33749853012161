import React, { useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import MapPin from './MapPin'
import { CompanyDto } from 'types/dto'
import useGeoLocation from 'shared/utils/useGeoLocation'
import { routePaths } from 'routes/routes'
import useSupercluster from 'use-supercluster'
import MapItemLink from './mapItemLink/MapItemLink'
import styles from './Map.module.css'

const Cluster = ({ children, lat, lng }) => children

interface MapProps {
  data: CompanyDto[]
}
const Map: React.FC<MapProps> = ({ data }) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto>(null)
  const geoLoc = useGeoLocation()
  const [center, setCenter] = useState(geoLoc)
  const [zoom, setZoom] = useState(12)
  const [bounds, setBounds] = useState(null)

  const mapRef = useRef()

  const points = data.map((company) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      companyId: company.id,
      category: 'category',
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(company.address.lon),
        parseFloat(company.address.lat),
      ],
    },
  }))

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 20,
    },
  })

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API_KEY }}
      defaultCenter={{
        lat: 44.786568,
        lng: 20.448922,
      }}
      center={center}
      defaultZoom={12}
      zoom={zoom}
      resetBoundsOnResize
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom)
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
      }}
    >
      {clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties

        if (isCluster) {
          return (
            <Cluster
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  )
                  if (mapRef.current) {
                    //@ts-ignore
                    mapRef.current.setZoom(expansionZoom)
                    //@ts-ignore
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                  }
                }}
                className={styles.cluster_marker}
              >
                {pointCount}
              </div>
            </Cluster>
          )
        }

        return (
          <MapPin
            key={cluster.properties.companyId}
            lat={latitude}
            lng={longitude}
            onPinClick={() => {
              setCenter({
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              })
              setSelectedCompany(
                data.find(
                  (company) => company.id === cluster.properties.companyId
                )
              )
              setZoom(15)
            }}
          >
            {selectedCompany?.id === cluster.properties.companyId && (
              <div
                className={styles.selectedCompanyWrapper}
                style={{
                  transform: 'translate(-50%, -110%)',
                }}
              >
                <>
                  <div className={styles.selectedCompanyContainer}>
                    <button
                      className={styles.closeSelectedCompany}
                      onClick={() => {
                        setSelectedCompany(null)
                        setZoom(12)
                      }}
                    >
                      <p>X</p>
                    </button>

                    <MapItemLink
                      index={selectedCompany.id}
                      title={selectedCompany.name}
                      subtitle={`${selectedCompany.address.street}, ${selectedCompany.address.city}`}
                      image={selectedCompany.thumbnail}
                      link={routePaths.COMPANY.replace(
                        ':id',
                        selectedCompany.id.toString()
                      )}
                    />
                  </div>
                  <div className={styles.pinTriangle} />
                </>
              </div>
            )}
          </MapPin>
        )
      })}
    </GoogleMapReact>
  )
}

export default Map
