import React from 'react'
import ReactPaginate from 'react-paginate'
import styles from './Pagination.module.css'

interface Props {
  setActivePage?: any
  pageCount?: number
}

const Pagination: React.FC<Props> = ({ setActivePage, pageCount = 22 }) => {
  if (pageCount < 1) return null
  return (
    <div className={styles.paginate}>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={'Prethodna'}
        nextLabel={'Sledeća'}
        activeLinkClassName={styles.linkActive}
        onPageChange={(pageNumber) => {
          setActivePage(pageNumber.selected)
        }}
      />
    </div>
  )
}

export default Pagination
