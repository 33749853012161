import React from 'react'
import styles from './Loader.module.css'

const Loader: React.FC = () => (
  <div className={styles['loader-wrapper']}>
    <div className={styles.loader}>
      <div className={styles['loader-container']}>
        <div className={styles['loader-rotator']}>
          <div className={styles['loader-left']}>
            <div className={styles['loader-circle']} />
          </div>
          <div className={styles['loader-right']}>
            <div className={styles['loader-circle']} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Loader
