import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import styles from './ItemLink.module.css'

interface ItemLinkProps {
  index: number
  title: string
  subtitle: string
  image: string
  featured?: boolean
  premium?: boolean
  link?: string
  withShadow?: boolean
  description?: string
  selectFirst?: boolean
  rating?: number
  inRow?: boolean
}

const ItemLink: React.FC<ItemLinkProps> = ({
  index,
  title,
  subtitle,
  image,
  link = '/',
  description = '',
  featured = false,
  premium = false,
  withShadow = false,
  selectFirst = false,
  inRow = false,
  // rating = 0,
}) => {
  let shadowClasses = cx(styles.shadow, !withShadow && styles.noShadow)
  const itemLinkClasses = cx(
    styles.container,
    selectFirst && index === 0 && styles.first
  )

  return (
    <Link to={link}>
      <div className={itemLinkClasses}>
        <div
          id="shadow"
          className={`${shadowClasses} ${inRow ? styles.altShadow : ''} `}
        >
          <div
            id="top"
            className={`${styles.top} ${inRow ? styles.altTop : ''}`}
            style={{
              backgroundImage: `url(${image})`,
            }}
          >
            <div className={styles.gradient} />
            {featured && (
              <span className={styles.top_highlighted}>Istaknuto</span>
            )}
            {premium && (
              <span className={styles.top_premium}>Premium član</span>
            )}
          </div>
          <div className={styles.bottom}>
            <h4 className={styles.name}>{title}</h4>
            {/* <Ratings /> */}
            <div className={styles.description_wrapper}>
              <span className={styles.description}>{subtitle}</span>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ItemLink
