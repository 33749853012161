import { useHistory, useLocation } from 'react-router'

const useURLQuery = () => {
  const location = useLocation()
  const history = useHistory()

  const params = Object.fromEntries(new URLSearchParams(location.search))
  const setParam = (name: string, value: string) => {
    const newParams = Object.entries({
      ...params,
      [name]: value,
    }).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
    const search = new URLSearchParams(newParams).toString()
    history.push({ search })
  }

  return {
    params,
    setParam,
  }
}
export default useURLQuery
