import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import logo from 'assets/images/icons/logo-esnaf.svg'
import { routePaths } from 'routes/routes'
import TopBar from './topBar/TopBar'
import { Squash as Hamburger } from 'hamburger-react'
import styles from './Navigation.module.css'

export const navRoutes = [
  {
    route: routePaths.HOME,
    label: 'Početna',
  },
  {
    route: routePaths.COMPANIES,
    label: 'Firme',
  },
  {
    route: routePaths.REPAIRMEN,
    label: 'Majstori',
  },
  {
    route: routePaths.PRODUCTS,
    label: 'Proizvodi',
  },
  {
    route: routePaths.SERVICES,
    label: 'Usluge',
  },
]

const Navigation: React.FC<any> = ({
  setIsMobileMenuVisible,
  isMobileMenuVisible,
}) => {
  const [scrollPos, setScrollPos] = useState(0)
  const [direction, setDirection] = useState('')

  const onHandleScroll = () => {
    const windowScroll = window.scrollY || window.pageYOffset
    const isScrollingDown = windowScroll > scrollPos
    setDirection(isScrollingDown ? 'bottom' : 'top')
    setScrollPos(windowScroll)
  }

  useEffect(() => {
    document.addEventListener('scroll', onHandleScroll)
    return () => {
      document.removeEventListener('scroll', onHandleScroll)
    }
  })

  const height = window.innerHeight
  const animatedHeader = direction === 'bottom' && scrollPos > height * 0.25
  const navClasses = cx(
    styles.Navigation,
    animatedHeader && styles.Navigation_transformed
  )

  const location = useLocation()

  return (
    <div className={navClasses}>
      <TopBar />
      <div className={`${styles.Navigation_lower_container} global-container`}>
        <div className={styles.Navigation_lower}>
          <div className={styles.Navigation_lower__upper}>
            <div className={styles.Menu_mobile}>
              <Hamburger
                size={26}
                rounded={true}
                toggled={isMobileMenuVisible}
                toggle={setIsMobileMenuVisible}
                color="#555"
              />
            </div>
            <NavLink exact to={routePaths.HOME}>
              <img className={styles.Logo} src={logo} alt="Esnaf" />
            </NavLink>
            <div className={styles.Navigation_large_desktop}>
              {navRoutes.map((navRoute, index) => (
                <NavLink
                  exact
                  key={index}
                  activeClassName={styles.Main_button}
                  to={navRoute.route}
                  className={cx(
                    styles.button,
                    location.pathname === navRoute.route && styles.main
                  )}
                >
                  {navRoute.label}
                </NavLink>
              ))}
            </div>
          </div>
          <div className={styles.Navigation_lower__lower}>
            <NavLink
              activeClassName={styles.Main_button}
              className={`${styles.button} ${styles.main}`}
              to={routePaths.ADD_COMPANY}
            >
              Dodajte firmu
            </NavLink>
          </div>
        </div>
      </div>
      <div className={`${styles.Navigation_large} global-container`}>
        {navRoutes.map((navRoute, index) => (
          <NavLink
            exact
            key={index}
            activeClassName={styles.Main_button}
            to={navRoute.route}
            className={cx(
              styles.button,
              location.pathname === navRoute.route && styles.main
            )}
          >
            {navRoute.label}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default Navigation
