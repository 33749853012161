import { useQuery } from 'react-query'
import {
  fetchStoreById,
  fetchStores,
  fetchStoresByIds,
} from '../services/storeService'

export const usePagedStores = (params: {
  page?: number
  size?: number
  sort?: string
  [key: string]: any
}) =>
  useQuery(
    ['stores', params],
    () =>
      fetchStores({
        params,
      }).then((data) => ({
        page: data.content,
        total: data.totalElements,
      })),
    { keepPreviousData: true }
  )

export const useStore = (id: number) =>
  useQuery(['store', id], () => fetchStoreById(id), { enabled: !!id })

export const useStoreByIds = (ids: number[]) =>
  useQuery(['stores', ids], () => fetchStoresByIds(ids), { enabled: !!ids })
