import React from 'react'
import FindSolution from 'components/home/findSolution/FindSolution'
import HowCanWeHelp from 'components/home/howCanWeHelp/HowCanWeHelp'
import Banners from 'components/home/banners/Banners'
import LatestCompanies from 'components/home/latest/LatestCompanies'
import { useLatestCompanies } from 'utils/companyUtils'

const HomeScreen: React.FC = () => {
  const latestCompanies = useLatestCompanies()

  return (
    <div>
      <FindSolution />
      <LatestCompanies
        companies={latestCompanies.data?.all}
        label="Najnovije firme"
      />
      <HowCanWeHelp />
      <Banners />
    </div>
  )
}

export default HomeScreen
