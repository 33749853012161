import React from 'react'
import styles from './TermsOfUse.module.css'

const TermsOfUse = () => {
  return (
    <div className={`${styles.TermsOfUse} global-container`}>
      <h2>Uslovi korišćenja</h2>
      <p>
        Dobro došli na <span>portal Esnaf.rs</span>
      </p>
      <p className={styles.Italic}>
        Korišćenjem bilo kog dela portala Esnaf automatski prihvatate sva
        aktuelna pravila korišćenja:
      </p>
      <p>
        1. <span>Korisnici su dužni</span> da redovno čitaju pravila korišćenja,
        te se smatra da su korisnici kontinuiranim korišćenjem portala Esnaf,
        ili bilo kojeg njegovog dela, u svakom trenutku upoznati s aktuelnim
        pravilima korišćenja, te da su ih razumeli u celosti.
      </p>
      <p>
        2. <span>Ni jedan deo portala Esnaf</span> ne sme se koristiti u
        nezakonite svrhe, ni za promovisanje istih.
      </p>
      <p>
        3. <span>Firma “Esnaf”</span> posetiocima u dobroj nameri obezbeđuje
        korišćenje sadržaja portala Esnaf. Svi posetioci imaju pravo da
        besplatno koriste sadržaje portala Esnaf, ukoliko ne krše pravila
        korišćenja.
      </p>
      <p>
        4. <span>Portal Esnaf</span> se sastoji od vlastitih sadržaja, sadržaja
        partnera i oglašivača, besplatnih sadržaja i linkova na spoljnih
        stranica.
      </p>
      <p>
        5. <span>Esnaf</span> sadržaje objavljuje u dobroj nameri. Sve sadržaje
        portala Dizajn Magazina koristite na vlastitu odgovornost i Esnaf se ne
        može smatrati odgovornim za bilo kakvu štetu nastalu korišćenjem.
      </p>
      <p>
        6. <span>Esnaf</span> portalu i svim njegovim sadržajima dozvoljen je
        osobama bilo kog uzrasta.
      </p>
      <p>
        7. <span>Esnaf polaže autorska</span> prava na sve vlastite sadržaje
        (tekstualne, vizuelne i audio materijale, baze podataka i programerski
        kod). Neovlašćeno korišćenje bilo kog dela portala smatra se kršenjem
        autorskih prava portala Dizajn Magazina i podložno je tužbi.
      </p>
      <p>
        8. <span>Esnaf</span> bilo kog dela portala bez dozvole vlasnika
        autorskih prava (Dizajn Magazin, partneri…) smatra se kršenjem autorskih
        prava i podložno je tužbi.
      </p>
      <p>
        9. <span>Ukoliko smatrate da je Esnaf</span> povredio Vaša autorska
        prava, slučaj će biti odmah razmotren, a sporni sadržaji biće uklonjeni
        odmah po eventualnom ustanovljavanju istinitosti sadržaja žalbe.
      </p>
      <p>
        10. <span>Esnaf</span> sadrži linkove koji vode na web stranice izvan
        vlastitog portala.
      </p>
      <p>
        11. <span>Esnaf linkove</span> objavljuje u dobroj nameri i ne može se
        smatrati odgovornim za sadržaje izvan portala.
      </p>
      <p>
        12. <span>Esnaf</span> objavljuje video fajlove koji su locirani na
        serverima YouTube.com.
      </p>
      <p>
        13. <span>Video fajlovi koji</span> su označeni kao „Related“
        postavljeni su po automatizmu YouTube softvera na koji Esnaf nema niti
        može imati uticaja. Samim tim Esnaf nije odgovoran za sadržaje ovih
        video fajlova.
      </p>
      <p>
        14. <span>Esnaf</span> zadržava pravo da bez najave povremeno{' '}
        <span>promeni ili modifikuje pravila</span>.
      </p>
      <p>Beograd; 06.11.2019.</p>
    </div>
  )
}

export default TermsOfUse
