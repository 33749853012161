import React from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { useCompanies } from 'utils/companyUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import { useCategories } from 'utils/categoryUtils'
import Select from 'components/shared/select/Select'
import Range from 'components/forms/rangeSlider/Range'
import { CategoryDto, CompanyDto, SubCategoryDto } from 'types/dto'
import MoreOptions from '../moreOptions/MoreOptions'
import styles from '../product/ProductsForm.module.css'

interface FormData {
  category: CategoryDto
  company: CompanyDto
  subCategory: SubCategoryDto
  search: string
  range: number[]
}

const ServicesForm = () => {
  const { params } = useURLQuery()
  const history = useHistory()
  const categories = useCategories()
  const companies = useCompanies()

  const { register, handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      search: params.search || null,
      company: companies.data?.byId[params.company] || null,
      category: categories.data?.byId[params.category] || null,
      range: [0, 500000],
    },
  })
  const onSubmit = handleSubmit(({ search, company, category, range }) => {
    history.push({
      pathname: '/usluge',
      search: `?${search ? `search=${search}&` : ''}${
        company ? `company=${company.id}&` : ''
      }${category ? `category=${category.id}&` : ''}${
        range[0] ? `priceFrom=${range[0]}&` : ''
      }${range[1] ? `priceTo=${range[1]}&` : ''}`,
    })
  })

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Pretraga usluga</h4>
      <form onSubmit={onSubmit}>
        <div className={styles.upper}>
          <Controller
            name="company"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Odaberite kompaniju"
                options={companies.data?.all}
                selectValue={value}
                setSelectValue={(option: CompanyDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            render={({ onChange, value }) => (
              <Select
                placeholder="Odaberite kategoriju"
                options={categories.data?.all}
                selectValue={value}
                setSelectValue={(option: CategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.Select_title}
                customStyles={styles.Select}
              />
            )}
          />
        </div>
        <div className={styles.lower}>
          <Controller
            name="range"
            control={control}
            render={({ onChange, value }) => (
              <div className={styles.slidecontainer}>
                <Range onChange={onChange} value={value} />
              </div>
            )}
          />
          <input
            name="search"
            type="text"
            className={styles.input}
            placeholder="Naziv usluge, brend...."
            ref={register}
          />
          <input type="submit" className={styles.findButton} value="Traži" />
        </div>
      </form>
      <MoreOptions />
    </div>
  )
}

export default ServicesForm
