import React from 'react'
import mapIcon from '../../../assets/images/icons/map-icon.png'

interface Props {
  onPinClick(): void
  [key: string]: any
}

const MapPin: React.FC<Props> = ({ onPinClick, children }) => {
  return (
    <>
      <img
        src={mapIcon}
        alt="locationPin"
        onClick={onPinClick}
        style={{
          transform: 'translate(-50%, -100%)',
        }}
      />

      {children}
    </>
  )
}

export default MapPin
