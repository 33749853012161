import React, { useState, useEffect } from 'react'
import Lightbox from 'react-image-lightbox'
import cx from 'classnames'
import { ImageDto } from 'types/dto'
import { useCoverAlbum } from 'utils/albumUtils'
import Loader from 'components/shared/loader/Loader'
import 'react-image-lightbox/style.css'
import styles from './CompanyBanner.module.css'

interface Props {
  albumId: number
}

const CompanyBanner: React.FC<Props> = ({ albumId }) => {
  const coverAlbum = useCoverAlbum(albumId)

  const images = coverAlbum.data?.images

  const [activeItem, setActiveItem] = useState<ImageDto>()
  const [activeFullScreenImage, setActiveFullScreenImage] = useState<ImageDto>()

  useEffect(() => {
    if (images) {
      setActiveItem(images[0])
    }
  }, [images])

  if (coverAlbum.status === 'error') {
    //TODO return Not Found
    return <Loader />
  }

  if (coverAlbum.status === 'loading') {
    return <Loader />
  }

  if (!images || images?.length === 0) {
    return null
  }

  return (
    <>
      <div className={`${styles.companyBanner} global-container`}>
        <div
          onClick={() => {
            setActiveFullScreenImage(activeItem)
          }}
        >
          <div
            className={styles.companyBanner__img}
            style={{ backgroundImage: `url(${activeItem?.url})` }}
          />
        </div>
        <div className={styles.companyBanner__items}>
          {images.map((item, id) => {
            return (
              <img
                className={cx(
                  styles.image,
                  activeItem?.id === item.id && styles.active_image
                )}
                onClick={() => setActiveItem(item)}
                key={id}
                src={item.url}
                alt="bannerActive"
              />
            )
          })}
        </div>
      </div>

      {activeFullScreenImage && (
        <Lightbox
          mainSrc={images[images.indexOf(activeFullScreenImage)].url}
          nextSrc={
            images[(images.indexOf(activeFullScreenImage) + 1) % images.length]
              .url
          }
          prevSrc={
            images[
              (images.indexOf(activeFullScreenImage) + images.length - 1) %
                images.length
            ].url
          }
          onCloseRequest={() => {
            setActiveItem(activeFullScreenImage)
            setActiveFullScreenImage(null)
          }}
          onMovePrevRequest={() =>
            setActiveFullScreenImage(
              images[
                (images.indexOf(activeFullScreenImage) + images.length - 1) %
                  images.length
              ]
            )
          }
          onMoveNextRequest={() =>
            setActiveFullScreenImage(
              images[
                (images.indexOf(activeFullScreenImage) + 1) % images.length
              ]
            )
          }
        />
      )}
    </>
  )
}

export default CompanyBanner
