import Tabs from 'components/shared/tabs/Tabs'
import Gallery from 'components/shared/tabs/categories/gallery/Gallery'
import React, { useState } from 'react'
import { ServiceDto } from 'types/dto'
import Parser from 'html-react-parser'
import ServiceList from 'components/shared/list/ServicesList'

interface Props {
  service: ServiceDto
}

const ServiceTabs: React.FC<Props> = ({ service }) => {
  const [activeTab, setActiveTab] = useState('aTab')

  const { name, description, albumIds } = service

  const tabsItems = {
    aTab: Parser(description),
    bTab: <Gallery albumIds={albumIds} breadcrumbStart={name} />,
    dTab: <ServiceList data={[]} />,
  }
  return (
    <div>
      <Tabs
        active={activeTab}
        onChange={(activeTab) => setActiveTab(activeTab)}
      >
        <p key="aTab">DODATNI OPIS</p>
        <p key="bTab">GALERIJA</p>
        <p key="cTab">USLUGE IZ ISTE KATEGORIJE</p>
      </Tabs>
      <div>{tabsItems[activeTab]}</div>
    </div>
  )
}

export default ServiceTabs
