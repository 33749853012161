import React from 'react'
import { Link } from 'react-router-dom'
import { routePaths } from 'routes/routes'
import { useBanners } from 'utils/bannerUtils'
import styles from './Banners.module.css'

const Banners: React.FC = () => {
  const bannerQuery = useBanners()

  if (bannerQuery.loading) return null

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Istaknuti proizvođači</h4>
      <div className={`${styles.content} global-container`}>
        <Link
          to={routePaths.COMPANY.replace(
            ':id',
            bannerQuery.byPosition['TOP_LEFT'].companyId.toString()
          )}
          className={styles.item_container}
        >
          <img
            alt={bannerQuery.byPosition['TOP_LEFT'].company.name}
            src={bannerQuery.byPosition['TOP_LEFT'].url}
          />
          <span className={styles.item_title}>
            {bannerQuery.byPosition['TOP_LEFT'].company.name}
          </span>
        </Link>
        <Link
          to={routePaths.COMPANY.replace(
            ':id',
            bannerQuery.byPosition['TOP_RIGHT'].companyId.toString()
          )}
          className={styles.item_container}
        >
          <img
            alt={bannerQuery.byPosition['TOP_RIGHT'].company.name}
            src={bannerQuery.byPosition['TOP_RIGHT'].url}
          />
          <span className={styles.item_title}>
            {bannerQuery.byPosition['TOP_RIGHT'].company.name}
          </span>
          <span className={styles.item_highlighted}>Istaknuto</span>
        </Link>
        <Link
          to={routePaths.COMPANY.replace(
            ':id',
            bannerQuery.byPosition['BOTTOM_LEFT'].companyId.toString()
          )}
          className={styles.item_container}
        >
          <img
            alt={bannerQuery.byPosition['BOTTOM_LEFT'].company.name}
            src={bannerQuery.byPosition['BOTTOM_LEFT'].url}
          />
          <span className={styles.item_title}>
            {bannerQuery.byPosition['BOTTOM_LEFT'].company.name}
          </span>
        </Link>

        <Link
          to={routePaths.COMPANY.replace(
            ':id',
            bannerQuery.byPosition['BOTTOM_RIGHT'].companyId.toString()
          )}
          className={styles.item_container}
        >
          <img
            alt={bannerQuery.byPosition['BOTTOM_RIGHT'].company.name}
            src={bannerQuery.byPosition['BOTTOM_RIGHT'].url}
          />
          <span className={styles.item_title}>
            {bannerQuery.byPosition['BOTTOM_RIGHT'].company.name}
          </span>
          <span className={styles.item_highlighted}>Istaknuto</span>
        </Link>
      </div>
    </div>
  )
}

export default Banners
