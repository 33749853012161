import React from 'react'
import Slider from 'rc-slider'
import './RangeSlider.css'
import '../../../../node_modules/rc-slider/assets/index.css'

interface Props {
  onChange(value): void
  value: number
}

const ESlider = ({ onChange, value }: Props) => {
  return (
    <div className="filter-slider">
      <Slider
        className="filter-slider__input"
        min={10}
        defaultValue={50}
        onChange={(value) => {
          onChange(value === 100 ? null : value)
        }}
        step={10}
      />

      <div className="filter-slider__details-center">
        <span>{value === null ? 'Neograniceno' : `${value} km`}</span>
      </div>
    </div>
  )
}

export default ESlider
