import { useEffect, useState } from 'react'

const useGeoLocation = () => {
  const [geoLoc, setGeoLoc] = useState<{ lat: number; lng: number }>({
    lat: 44.787197,
    lng: 20.457273,
  })
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setGeoLoc({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }, [])

  return geoLoc
}

export default useGeoLocation
