import React from 'react'
import styles from './ServicePackages.module.css'

const ServicePackages = () => {
  return (
    <div className={styles.ServicePackages}>
      <div className={`${styles.Sellers} global-container`}>
        <div className={styles.Info}>
          <h2>Prodavci</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh
          </p>
        </div>
        <div className={styles.Boxes}>
          <div className={styles.BoxOne}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>ŠEGRT</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija</p>
              <p>Video galerija</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
          <div className={styles.BoxTwo}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>KALFA</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija</p>
              <p>Video galerija(+ Promo video)</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxDesc}>
              <p>Katalog</p>
              <p>Akcije</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
          <div className={styles.BoxThree}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>MAJSTOR</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija (+ Slike pre-posle, Slike 360 view)</p>
              <p>Video galerija (+ Promo video)</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxDesc}>
              <p>Katalog</p>
              <p>Akcije</p>
              <p>Promocije na društvenim mrežama</p>
              <p>Istaknuto mesto na sajtu</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.Manufacturers} global-container`}>
        <div className={styles.Info}>
          <h2>Proizvođači</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh
          </p>
        </div>
        <div className={styles.Boxes}>
          <div className={styles.BoxOne}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>ŠEGRT</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija</p>
              <p>Video galerija</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
          <div className={styles.BoxTwo}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>KALFA</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija</p>
              <p>Video galerija (+ Promo video)</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxDesc}>
              <p>Katalog</p>
              <p>Akcije</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
          <div className={styles.BoxThree}>
            <div className={styles.BoxTitle}>
              <h5>esnaf</h5>
              <h3>MAJSTOR</h3>
            </div>
            <div className={styles.BoxDesc}>
              <p>Opis</p>
              <p>Radno vreme</p>
              <p>Foto galerija (+ Slike pre-posle, Slike 360 view)</p>
              <p>Video galerija (+ Promo video)</p>
              <p>Kontakt</p>
              <p>Mapa</p>
              <p>Link ka sajtu</p>
            </div>
            <div className={styles.BoxDesc}>
              <p>Katalog</p>
              <p>Akcije</p>
              <p>Promocije na društvenim mrežama</p>
              <p>Istaknuto mesto na sajtu</p>
            </div>
            <div className={styles.BoxPrice}>
              <h3>xxx</h3>
              <div>
                <h5>RSD</h5>
                <p>mesečno</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicePackages
