import React, { useState } from 'react'
import useURLQuery from 'shared/hooks/useURLQuery'
import { pageSize } from 'shared/utils/pagination'
import Loader from 'components/shared/loader/Loader'
import { usePagedServices } from 'utils/serviceUtils'
import ServicesForm from 'components/forms/service/ServicesForm'
import Pagination from 'components/shared/pagination/Pagination'
import ServiceList from 'components/shared/list/ServicesList'

const ServicesScreen = () => {
  const { params } = useURLQuery()
  const [page, setPage] = useState(0)

  const services = usePagedServices({
    page,
    size: pageSize,
    name: params.search,
    'company.id': params.company,
    'categories.id': params.category,
    priceFrom: params.priceFrom,
    priceTo: params.proceTo,
    sort: params.sort,
  })

  if (services.error) {
    return <Loader />
  }
  if (services.status === 'loading') {
    return <Loader />
  }

  return (
    <div>
      <div className="gradient">
        <div className="global-container">
          <ServicesForm />
        </div>
      </div>
      <div className="global-container">
        <ServiceList data={services.data?.page} showSortOptions={true} />
        <Pagination
          setActivePage={setPage}
          pageCount={services.data?.total / pageSize}
        />
      </div>
    </div>
  )
}

export default ServicesScreen
