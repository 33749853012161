import React from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { useCities } from 'utils/cityUtils'
import { CategoryDto, City } from 'types/dto'
import search from 'assets/images/icons/search.svg'
import { useCategories } from 'utils/categoryUtils'
import Select from 'components/shared/select/Select'
import { routePaths } from 'routes/routes'
import styles from './FindSolution.module.css'

interface FormData {
  search: string
  city: City
  category: CategoryDto
}

const FindSolution = () => {
  const history = useHistory()
  const categories = useCategories()
  const cities = useCities()

  const { register, handleSubmit, control } = useForm<FormData>()
  const onSubmit = handleSubmit(({ search, city, category }) => {
    history.push({
      pathname: '/firme',
      search: `?${search ? `search=${search}` : ''}${
        city ? `&city=${city.id}` : ''
      }${category ? `&category=${category.id}` : ''}`,
    })
  })

  return (
    <div className={styles.FindSolution}>
      <div className="global-container">
        <h2 className={styles.FindSoluton_title}>Pronađite rešenje</h2>
        <form className={styles.FindSolution_form} onSubmit={onSubmit}>
          <Controller
            name="city"
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => (
              <Select
                placeholder="Svi gradovi"
                options={cities.data?.all}
                selectValue={value}
                setSelectValue={(option: City) => {
                  onChange(option)
                }}
                titleStyles={styles.FindSolution_Select}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            defaultValue={null}
            render={({ onChange, value }) => (
              <Select
                placeholder="Sve kategorije"
                options={categories.data?.all}
                selectValue={value}
                setSelectValue={(option: CategoryDto) => {
                  onChange(option)
                }}
                titleStyles={styles.FindSolution_Select}
              />
            )}
          />
          <div className={styles.FindSolution_form_buttonWrapper}>
            <input
              name="search"
              type="text"
              className={styles.FindSolution_form_input}
              placeholder="Traži..."
              ref={register}
            />
            <button type="submit" className={styles.FindSolution_form_button}>
              <img src={search} alt="submit" />
            </button>
          </div>
        </form>
        <div className={styles.FindSolution_advanced}>
          <span className={styles.FindSolution_advaned_title}>
            Potrebno vam je još opcija za pretragu?
          </span>

          <NavLink
            to={routePaths.COMPANIES}
            className={styles.FindSolution_advanced_button}
          >
            Napredna pretraga
          </NavLink>
        </div>
        <h4 className={styles.FindSolution_solutions}>
          Imamo preko 1000 rešenja za vas!
        </h4>
      </div>
    </div>
  )
}

export default FindSolution
