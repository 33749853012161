import React, { useState } from 'react'
import cx from 'classnames'
import downGray from '../../../assets/images/icons/down.svg'
import upGray from '../../../assets/images/icons/upGray.svg'
import { uniqueId } from 'lodash'
import styles from './Select.module.css'

const Select: React.FC<any> = ({
  options,
  selectValue,
  setSelectValue,
  placeholder = 'Odaberi filter',
  cancelFilterName = 'Poništi odabrano',
  titleStyles = styles.Select_title,
  customStyles = '',
}) => {
  const cancelOption = {
    id: uniqueId(),
    name: cancelFilterName,
  }

  const [optionsVisible, setOptionsVisible] = useState(false)

  const onSelectValue = (option, event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectValue(option)
    setOptionsVisible(false)
  }

  let selectClasses = cx(
    styles.Select,
    optionsVisible && styles.Select_optionsVisible
  )

  let optionsClasses = cx(
    styles.Select_options,
    optionsVisible && styles.Select_options_visible
  )

  return (
    <div
      onClick={() => setOptionsVisible(!optionsVisible)}
      className={`${selectClasses} ${customStyles}`}
    >
      <button
        className={`${styles.Select_title} ${titleStyles}`}
        onClick={(event) => {
          event.preventDefault()
        }}
      >
        {selectValue ? selectValue.name : placeholder}
        <img src={optionsVisible ? upGray : downGray} alt="up" />
      </button>

      {options && (
        <div className={optionsClasses}>
          <button
            key={cancelOption.id}
            onClick={(event) => onSelectValue(null, event)}
            className={cx(
              styles.Select_cancelOption,
              selectValue &&
                selectValue.id === cancelOption.id &&
                styles.Select_cancelOption_selected
            )}
          >
            {cancelOption.name}
          </button>
          {options.map((option) => {
            const optionClasses = cx(
              styles.Select_singleOption,
              selectValue &&
                selectValue.id === option.id &&
                styles.Select_singleOption_selected
            )
            return (
              <button
                onClick={(event) => onSelectValue(option, event)}
                className={optionClasses}
                key={option.id}
              >
                {option.name}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Select
