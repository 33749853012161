import { join } from 'lodash'
import { Page, ProductCatalogueDto } from 'types/dto'
import http, { apiUrl } from './httpService'

export const fetchProductCatalogues = async (params = {}) => {
  const url = apiUrl + '/productCatalogue/findAll'
  const { data } = await http.get<Page<ProductCatalogueDto>>(url, params)
  return data
}

export const fetchProductCatalogueById = async (id: string | number) => {
  const url = apiUrl + '/productCatalogue/findById'

  const { data } = await http.get<ProductCatalogueDto>(url, {
    params: { id },
  })

  return data
}

export const fetchProductCataloguesByIds = async (ids: number[]) => {
  const url = apiUrl + '/productCatalogue/findByIds'

  const { data } = await http.get<Page<ProductCatalogueDto>>(url, {
    params: { ids: join(ids, ',') },
  })

  return data
}

export const fetchProductPriceRange = async () => {
  const url = apiUrl + '/productCatalogue/priceRange'
  const { data } = await http.get<{
    highestPrice: number
    lowestPrice: number
  }>(url)
  return data
}
