import { HelpingSegmentDto, HelpingStepDto } from '../types/dto'
import http, { apiUrl } from './httpService'

export const fetchHelpingSegments = async () => {
  const url = apiUrl + '/helpingSegment/findAll'
  const { data } = await http.get<HelpingSegmentDto[]>(url)
  return data
}

export const fetchHelpingSegment = async (id: string | number) => {
  const url = apiUrl + '/helpingSegment/findById'

  const { data } = await http.get<HelpingSegmentDto>(url, {
    params: { id },
  })

  return data
}

export const fetchHelpingSteps = async () => {
  const url = apiUrl + '/step/findAll'
  const { data } = await http.get<HelpingStepDto[]>(url)
  return data
}
