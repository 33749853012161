import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HomeScreen from 'screens/homeScreen/HomeScreen'
import CompaniesScreen from 'screens/companiesScreen/CompaniesScreen'
import CompanyScreen from 'screens/companyScreen/CompanyScreen'
import PrivacyAgreement from 'screens/privacyAgreement/PrivacyAgreement'
import TermsOfUse from 'screens/termsOfUse/TermsOfUse'
import Packages from 'screens/servicePackages/ServicePackages'
import AddCompanyScreen from 'screens/addCompanyScreen/AddCompanyScreen'
import HowCanWeHelpScreen from 'screens/howCanWeHelpScreen/HowCanWeHelpScreen'
import ProductsScreen from 'screens/productsScreen/ProductsScreen'
import ProductScreen from 'screens/productScreen/ProductScreen'
import RepairmenScreen from 'screens/repairmenScreen/RepairmenScreen'
import RepairmanScreen from 'screens/repairmanScreen/RepairmanScreen'
import ServicesScreen from 'screens/servicesScreen/ServicesScreen'
import ServiceScreen from 'screens/serviceScreen/ServiceScreen'
import ProductCatalogueScreen from 'screens/productCatalogueScreen/ProductCatalogueScreen'
import NotFoundScreen from 'screens/notFoundScreen/NotFoundScreen'

const publicPath = '/dev'

export const routePaths = {
  HOME: publicPath,
  COMPANIES: `${publicPath}firme`,
  REPAIRMEN: `${publicPath}majstori`,
  PRODUCTS: `${publicPath}proizvodi`,
  COMPANY: `${publicPath}firma/:id`,
  REPAIRMAN: `${publicPath}majstor/:id`,
  PRODUCT: `${publicPath}proizvod/:id`,
  PRODUCT_CATALOGUE: `${publicPath}katalog/:id`,
  SERVICES: `${publicPath}usluge`,
  SERVICE: `${publicPath}usluga/:id`,
  PRIVACY: `${publicPath}privatnost`,
  TERMS: `${publicPath}usloviKoriscenja`,
  PACKAGES: `${publicPath}paketi`,
  ADD_COMPANY: `${publicPath}dodajteFirmu`,
  HOW_CAN_WE_HELP: `${publicPath}uputsvto/:id`,
  NOT_FOUND: '/notFound',
}

const routes = () => (
  <Switch>
    <Route exact path={routePaths.HOME} render={() => <HomeScreen />} />
    <Route path={routePaths.COMPANIES} render={() => <CompaniesScreen />} />
    <Route path={routePaths.REPAIRMEN} render={() => <RepairmenScreen />} />
    <Route path={routePaths.PRODUCTS} render={() => <ProductsScreen />} />
    <Route path={routePaths.COMPANY} render={() => <CompanyScreen />} />
    <Route path={routePaths.REPAIRMAN} render={() => <RepairmanScreen />} />
    <Route path={routePaths.PRODUCT} render={() => <ProductScreen />} />
    <Route
      path={routePaths.PRODUCT_CATALOGUE}
      render={() => <ProductCatalogueScreen />}
    />
    <Route path={routePaths.SERVICES} render={() => <ServicesScreen />} />
    <Route path={routePaths.SERVICE} render={() => <ServiceScreen />} />
    <Route path={routePaths.ADD_COMPANY} render={() => <AddCompanyScreen />} />
    <Route path={routePaths.PRIVACY} render={() => <PrivacyAgreement />} />
    <Route path={routePaths.TERMS} render={() => <TermsOfUse />} />
    <Route path={routePaths.PACKAGES} render={() => <Packages />} />
    <Route
      path={routePaths.HOW_CAN_WE_HELP}
      render={() => <HowCanWeHelpScreen />}
    />
    <Route component={NotFoundScreen} />
  </Switch>
)

export default routes
