import React from 'react'
import Parser from 'html-react-parser'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { routePaths } from 'routes/routes'
import { useCompany } from 'utils/companyUtils'
import Loader from 'components/shared/loader/Loader'
import { useProductCatalogue } from 'utils/productCatalogueUtils'
import ProductCatalogueTabs from './ProductCatalogueTabs'
import { useSubCategories } from 'utils/categoryUtils'
import styles from './ProductCatalogueScreen.module.css'
import BackButton from 'components/shared/backButton/BackButton'

const ProductCatalogueScreen = () => {
  const { id } = useParams()
  const productCatalogue = useProductCatalogue(id)
  const subCategories = useSubCategories()

  const manufacturer = useCompany(productCatalogue.data?.product.manufacturerId)

  if (productCatalogue.status === 'error') {
    //TODO return  Not Found
    return <Loader />
  }

  if (productCatalogue.status === 'loading') {
    return <Loader />
  }

  const {
    onSale,
    discountPrice,
    price,
    product: {
      subCategoryIds,
      name,
      shortDescription,
      manufacturerId,
      coverImageUrl,
      attributes,
      // rating,
    },
  } = productCatalogue.data

  return (
    <div className={`${styles.singleProductScreen} global-container`}>
      <div className={styles.product_header}>
        <div className={styles.product_header__left}>
          <BackButton />
          <img
            className={styles.product_header_image}
            src={coverImageUrl}
            alt=""
          />
        </div>
        <div className={styles.product_header_content}>
          <div className={styles.product_title}>
            <div>
              <h3>{name}</h3>
              <p>
                {subCategories.data?.all.map((subCategory) =>
                  subCategoryIds.includes(subCategory.id)
                    ? `${subCategory.name} `
                    : ''
                )}
              </p>
              <Link
                to={routePaths.COMPANY.replace(
                  ':id',
                  manufacturerId.toString()
                )}
              >
                <p>{manufacturer.data?.name}</p>
              </Link>
              {/* <Ratings initialRating={rating.average} /> */}
              {onSale ? (
                <>
                  <p
                    className={styles.product_price_disabled}
                  >{`cena : ${price} rsd`}</p>
                  <p
                    className={styles.product_price__discount}
                  >{`akcijska cena : ${discountPrice} rsd`}</p>
                </>
              ) : (
                <p className={styles.product_price}>{`cena : ${price} rsd`}</p>
              )}
            </div>
          </div>

          <div className={styles.product_decription}>
            {Parser(shortDescription || '')}
          </div>
          <div className={styles.product_info}>
            <div>
              {attributes?.map((att) => (
                <p key={att.id}>{`• ${att.name.toUpperCase()}: ${
                  att.value
                } `}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ProductCatalogueTabs productCatalogue={productCatalogue.data} />
    </div>
  )
}

export default ProductCatalogueScreen
