export const getBreakpoint = () => {
  const width = window.innerWidth
  if (width < 768) {
    return 'sm'
  } else if (width < 1024) {
    return 'md'
  } else if (width < 1280) {
    return 'lg'
  } else {
    return 'lg'
  }
}

export const MAX_VISIBLE_LINKS = {
  sm: 1,
  md: 2,
  lg: 3,
}
