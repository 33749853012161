import Tabs from 'components/shared/tabs/Tabs'
import Gallery from 'components/shared/tabs/categories/gallery/Gallery'
import React, { useState } from 'react'
import { ProductDto } from 'types/dto'
import Parser from 'html-react-parser'
import { useProductByIds } from 'utils/productUtils'
import { useProductCatalogues } from 'utils/productCatalogueUtils'
import { usePagedStores } from 'utils/storeUtils'
import EmptyTab from 'components/shared/tabs/EmptyTab'
import validateVideoUrl from '../../shared/utils/validateVideoUrl'
import ProductCatalogueList from 'components/shared/list/ProductCatalogueList'
import StoresList from 'components/shared/list/StoresList'
import ProductList from 'components/shared/list/ProductsList'

interface Props {
  product: ProductDto
}

const ProductTabs: React.FC<Props> = ({ product }) => {
  const [activeTab, setActiveTab] = useState('aTab')
  const relatedProducts = useProductByIds(product.relatedProductIds)

  const productCataloguesQuery = useProductCatalogues(
    product.productCatalogueIds
  )

  const stores = usePagedStores({
    [`company.id`]: product.manufacturerId,
  })

  const { name, description, videoUrl, albumIds } = product

  const tabsItems = {
    aTab: Parser(description || ''),
    bTab: <Gallery albumIds={albumIds} breadcrumbStart={name} />,
    cTab: (
      <ProductCatalogueList
        data={productCataloguesQuery.data?.all}
        showViewOptions={false}
      />
    ),
    dTab: <StoresList data={stores.data?.page} />,
    eTab: <ProductList data={relatedProducts.data} showViewOptions={false} />,
    fTab: validateVideoUrl(videoUrl) ? (
      <div>
        <iframe
          title={name}
          width="100%"
          height="400"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ) : (
      <EmptyTab text="Nema videa" />
    ),
  }
  return (
    <div>
      <Tabs
        active={activeTab}
        onChange={(activeTab) => setActiveTab(activeTab)}
      >
        <p key="aTab">DODATNI OPIS</p>
        <p key="bTab">GALERIJA</p>
        <p key="cTab">KATALOG</p>
        <p key="dTab">PRODAJNA MESTA</p>
        <p key="eTab">PROIZVODI IZ ISTE KATEGORIJE</p>
        <p key="fTab">VIDEO</p>
      </Tabs>
      <div>{tabsItems[activeTab]}</div>
    </div>
  )
}

export default ProductTabs
