import React from 'react'
import logo from 'assets/images/icons/logo-esnaf.svg'
import facebook from 'assets/images/icons/facebookGray.svg'
import facebookBlue from 'assets/images/icons/facebookBlue.svg'
import instagram from 'assets/images/icons/instagramGray.svg'
import instagramBlue from 'assets/images/icons/instagramBlue.svg'
import twitter from 'assets/images/icons/twitterGray.svg'
import twitterBlue from 'assets/images/icons/twitterBlue.svg'
import linkedin from 'assets/images/icons/linkedinGray.svg'
import linkedinBlue from 'assets/images/icons/linkedinBlue.svg'
import { NavLink } from 'react-router-dom'
import { routePaths } from 'routes/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import styles from './Footer.module.css'

const ArrowIcon = () => (
  <FontAwesomeIcon
    icon={faAngleRight}
    style={{
      fontSize: '20px',
      marginRight: '7px',
      color: '#ccc',
    }}
  />
)

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={`${styles.Footer_container} global-container`}>
        <div className={styles.Footer_main_container}>
          <div className={styles.Footer_info}>
            <NavLink to="/">
              <img src={logo} alt="Esnaf" />
            </NavLink>
            <p>
              ESNAF je internet platforma koja okuplja firme i pojedince koji
              šire ili uže povezana sa izgradnjom, opremanjem ili održavanjem
              doma sa jedne strane i potražioce ovih usluga sa druge strane.
            </p>
          </div>

          <div className={styles.Footer_links}>
            <h4>Korisni linkovi</h4>
            <div className={styles.Footer_links_container}>
              <div className={styles.Footer_links_left}>
                <NavLink
                  to={routePaths.ADD_COMPANY}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Dodajte firmu'}
                </NavLink>
                <NavLink
                  to={routePaths.PACKAGES}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Paketi usluga'}
                </NavLink>
                <NavLink
                  to={routePaths.TERMS}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Uslovi korišćenja'}
                </NavLink>
                <NavLink
                  to={routePaths.PRIVACY}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Politika privatnosti'}
                </NavLink>
              </div>
              <div className={styles.Footer_links_right}>
                <NavLink
                  to={routePaths.NOT_FOUND}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Kontakt'}
                </NavLink>
                <NavLink
                  to={routePaths.NOT_FOUND}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'O nama'}
                </NavLink>
                <NavLink
                  to={routePaths.NOT_FOUND}
                  className={styles.Footer_link_nav}
                >
                  <ArrowIcon />
                  {'Marketing'}
                </NavLink>
              </div>
            </div>
          </div>

          <div className={styles.Footer_contact}>
            <h4>Kontakt</h4>
            <div className={styles.Footer_contact__info}>
              <a href="tel:+381 61 20 53 993">Telefon: +381 61 20 53 993</a>
              <a href="mailto:office@esnaf.com">E-Mail: office@esnaf.com</a>
            </div>
            <div className={styles.Footer_contact__social_links}>
              <div className={styles.Facebook}>
                <img src={facebook} alt="facebook" />
                <img src={facebookBlue} alt="facebook" />
              </div>
              <div className={styles.Instagram}>
                <img src={instagram} alt="instagram" />
                <img src={instagramBlue} alt="instagram" />
              </div>
              <div className={styles.Twitter}>
                <img src={twitter} alt="twitter" />
                <img src={twitterBlue} alt="twitter" />
              </div>
              <div className={styles.Linkedin}>
                <img src={linkedin} alt="linkedin" />
                <img src={linkedinBlue} alt="linkedin" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Footer_copyright}>
          <p> &copy; 2020. ESNAF. Sva prava zadržana.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
