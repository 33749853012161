import React from 'react'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { navRoutes } from '../Navigation'
import styles from './MobileNavigation.module.css'

const MobileNavigation: React.FC<any> = ({
  isMobileMenuVisible,
  setIsMobileMenuVisible,
}) => {
  const mobileNavigationClasses = cx(
    styles.Mobile_navigation,
    isMobileMenuVisible && styles.Mobile_navigation__active
  )

  return (
    <div className={mobileNavigationClasses}>
      <h3 className={styles.Mobile_title}>MENU</h3>
      {navRoutes.map((navRoute, index) => (
        <NavLink
          exact
          key={index}
          to={navRoute.route}
          onClick={() => {
            setIsMobileMenuVisible()
          }}
        >
          <div className={styles.Mobile_button}>{navRoute.label}</div>
        </NavLink>
      ))}
    </div>
  )
}

export default MobileNavigation
