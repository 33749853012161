import { keyBy } from 'lodash'
import { useQuery } from 'react-query'
import {
  fetchCategories,
  fetchSubCategories,
} from '../services/categoryService'

export const useCategories = () =>
  useQuery('categories', () =>
    fetchCategories().then((categories) => ({
      all: categories,
      byId: keyBy(categories, 'id'),
    }))
  )

export const useSubCategories = () =>
  useQuery('subCategories', () =>
    fetchSubCategories().then((subCategories) => ({
      all: subCategories,
      byId: keyBy(subCategories, 'id'),
      byCategoryId: (id) =>
        subCategories.filter(
          (subCategory) => subCategory.categoryId.toString() === id.toString()
        ),
    }))
  )
