import { useQuery } from 'react-query'
import {
  fetchProductCatalogues,
  fetchProductCataloguesByIds,
  fetchProductCatalogueById,
  fetchProductPriceRange,
} from 'services/productCatalogueService'

export const useProductCatalogues = (ids) =>
  useQuery(
    ['productCatalogues', ids],
    () =>
      fetchProductCataloguesByIds(ids).then((productCatalogue) => ({
        all: productCatalogue.content,
      })),
    { enabled: ids?.length !== 0 }
  )

export const usePagedProductCatalogues = (params: {
  page?: number
  size?: number
  sort?: string
  [key: string]: any
}) =>
  useQuery(
    ['productCatalogues', params],
    () =>
      fetchProductCatalogues({
        params,
      }).then((data) => ({
        page: data.content,
        total: data.totalElements,
      })),
    { keepPreviousData: true }
  )

export const useProductCatalogue = (id: number) =>
  useQuery(['productCatalogue', id], () => fetchProductCatalogueById(id), {
    enabled: !!id,
  })

export const useProductRange = () =>
  useQuery('productCatalogueRange', () =>
    fetchProductPriceRange().then((data) => ({
      highestPrice: Math.ceil(data.highestPrice),
      lowestPrice: Math.ceil(data.lowestPrice),
    }))
  )
