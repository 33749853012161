import React from 'react'
import styles from './Latest.module.css'

interface Props {
  activeCategory: string
  setActiveCategory?(category: string): void
}
const Picker: React.FC<Props> = ({ activeCategory, setActiveCategory }) => (
  <form className={styles.radioButtonsWrapper}>
    <div className={styles.radioButton}>
      <input
        type="radio"
        id="company"
        name="picker"
        value="company"
        checked={activeCategory === 'company'}
        onChange={() => {
          setActiveCategory('company')
        }}
      />
      <span>Firme</span>
    </div>

    <div className={styles.radioButton}>
      <input
        type="radio"
        id="product"
        name="picker"
        value="product"
        checked={activeCategory === 'product'}
        onChange={() => {
          setActiveCategory('product')
        }}
      />
      <span>Proizvodi</span>
    </div>
  </form>
)

export default Picker
