import React from 'react'
import { useHistory } from 'react-router'
import { useForm, Controller } from 'react-hook-form'
import { useCities } from 'utils/cityUtils'
import useURLQuery from 'shared/hooks/useURLQuery'
import Select from 'components/shared/select/Select'
import Slider from 'components/forms/rangeSlider/Slider'
import { CategoryDto, City, SubCategoryDto } from 'types/dto'
import { useCategories, useSubCategories } from 'utils/categoryUtils'
import useGeoLocation from 'shared/utils/useGeoLocation'
import styles from './CompaniesForm.module.css'
import MoreOptions from '../moreOptions/MoreOptions'

interface FormData {
  search: string
  city: City
  category: CategoryDto
  range: number
  subCategory: SubCategoryDto
}

const CompaniesForm = () => {
  const { params } = useURLQuery()
  const history = useHistory()
  const categories = useCategories()
  const subCategories = useSubCategories()
  const cities = useCities()
  const geoLoc = useGeoLocation()

  const { register, handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: {
      city: cities.data?.byId[params.city] || null,
      search: params.search,
      category: categories.data?.byId[params.category] || null,
      subCategory: subCategories.data?.byId[params.subCategory] || null,
      range: 50,
    },
  })

  const onSubmit = handleSubmit(
    ({ search, city, category, range, subCategory }) => {
      history.push({
        pathname: '/firme',
        search: `?${search ? `search=${search}&` : ''}${
          city ? `city=${city.id}&` : ''
        }${category ? `category=${category.id}&` : ''}${
          subCategory ? `subCategory=${subCategory.id}&` : ''
        }${
          range && geoLoc
            ? `lat=${geoLoc.lat}&lon=${geoLoc.lng}&distance=${range}&`
            : ''
        }`,
      })
    }
  )

  const category = watch('category')

  return (
    <div className="gradient">
      <div className={styles.container}>
        <h4 className={styles.title}>Pronađite firmu</h4>
        <form onSubmit={onSubmit}>
          <input
            name="search"
            type="text"
            className={styles.input}
            placeholder="Traži..."
            ref={register}
          />
          <div className={styles.formWithSlider}>
            <Controller
              name="city"
              control={control}
              render={({ onChange, value }) => (
                <Select
                  placeholder="Svi gradovi"
                  options={cities.data?.all}
                  selectValue={value}
                  setSelectValue={(option: City) => {
                    onChange(option)
                  }}
                  titleStyles={styles.Select_title}
                  customStyles={styles.Select}
                />
              )}
            />
            <Controller
              name="range"
              control={control}
              render={({ onChange, value }) => (
                <div className={styles.sliderContainer}>
                  <Slider onChange={onChange} value={value} />
                </div>
              )}
            />
          </div>
          <div className={styles.space_between}>
            <Controller
              name="category"
              control={control}
              render={({ onChange, value }) => (
                <Select
                  placeholder="Sve kategorije"
                  options={categories.data?.all}
                  selectValue={value}
                  setSelectValue={(option: CategoryDto) => {
                    onChange(option)
                  }}
                  titleStyles={styles.Select_title}
                  customStyles={styles.Select}
                />
              )}
            />

            <Controller
              name="subCategory"
              control={control}
              render={({ onChange, value }) => (
                <Select
                  placeholder="Sve podkategorije"
                  options={
                    category
                      ? subCategories.data?.byCategoryId(category.id)
                      : []
                  }
                  selectValue={value}
                  setSelectValue={(option: SubCategoryDto) => {
                    onChange(option)
                  }}
                  titleStyles={styles.Select_title}
                  customStyles={styles.Select}
                />
              )}
            />
          </div>
          <MoreOptions>
            <input type="submit" className={styles.findButton} value="Traži" />
          </MoreOptions>
        </form>
      </div>
    </div>
  )
}

export default CompaniesForm
