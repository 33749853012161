import React, { useEffect, useLayoutEffect, useState } from 'react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import Routes, { routePaths } from './routes/routes'
import Footer from './components/footer/Footer'
import up from './assets/images/icons/upAbs.svg'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from 'shared/utils/scrollToTop'
import Navigation from './components/shared/navigation/Navigation'
import MobileNavigation from './components/shared/navigation/mobileNavigation/MobileNavigation'
import UnderConstructionScreen from 'screens/UnderConstruction'
import './App.css'

const App = () => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [backToTopVisible, setBackToTopVisible] = useState(false)

  const handleScroll = () => {
    const scrollPos = window.pageYOffset
    setBackToTopVisible(scrollPos > 1000 ? true : false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [])

  useEffect(() => {
    if (mobileMenu) {
      document.querySelector('html').classList.add('noScrollable')
      document.querySelector('body').classList.add('noScrollable')
    } else {
      document.querySelector('html').classList.remove('noScrollable')
      document.querySelector('body').classList.remove('noScrollable')
    }
  }, [mobileMenu])

  const contentClasses = cx(
    'Content',
    mobileMenu && 'Content--translated',
    window.location.pathname !== routePaths.COMPANIES && 'Footer-margin'
  )

  const backToTopClasses = cx(
    'BackToTop',
    backToTopVisible && 'BackToTop--visible'
  )

  //scroll to top
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  console.log('location', location)

  if (location.pathname === '/') {
    return <UnderConstructionScreen />
  }
  return (
    <div className="App">
      <MobileNavigation
        isMobileMenuVisible={mobileMenu}
        setIsMobileMenuVisible={() => setMobileMenu(!mobileMenu)}
      />

      <div className={contentClasses}>
        <Navigation
          isMobileMenuVisible={mobileMenu}
          setIsMobileMenuVisible={() => setMobileMenu(!mobileMenu)}
        />
        <Routes />
      </div>

      {window.location.pathname !== routePaths.COMPANIES ? <Footer /> : null}
      <div onClick={scrollToTop} className={backToTopClasses}>
        <img src={up} alt="Go up" />
      </div>
    </div>
  )
}

export default withRouter(App)
